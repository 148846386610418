function updateSliderCounterTryout(slider, currentSlide = 0){
	var currentSlide;
	var slidesCount;
	var sliderCounter = $(slider).find(' .slider__counter');
	currentSlide = $(slider).slick('slickCurrentSlide'); /*+ 1*/;
	//console.log(currentSlide);
    slidesCount = $(slider).find('.slick-slide').not('.slick-cloned').length;
    //console.log(slidesCount);
    sliderCounter.text(currentSlide +'/'+ slidesCount);
}

$(document).ready(function(){
	
	/*$('ul.tabbing li').click(function(){
		
		
	})*/
	$('.design-training-slider-body ul.tabbing li').click(function(){
		var filter = $(this).attr('data-filter');
		$('ul.tabbing li').removeClass('current');
		$(this).addClass('current');
		/*$('#tab-filter .grid-item').hide();
		if(filter == "alles"){
			$('#tab-filter .grid-item').show();
		}else{
			$('#tab-filter .'+filter).show();
		}
		$("#tab-1").hide();
		$("#tab-filter").show();*/
  		//var slideIndex = $('.design-training-slider .'+filter);
 		//var saveStuff = $('.design-training-slider .'+filter).html();
 		
		//Save HTML of elements
	});
	
	$('ul.training__programma__accordion li').click(function(){
		$('ul.training__programma__accordion li').removeClass('current');
		$(this).addClass('current');
	});
	
	
	/*$(".close-item").on('click', function() {
  var slideIndex = $(this).parent();
  var filterID = $(this).parent().attr("for");
  var saveStuff = $(this).parent().html();
  $('.box').slick('slickRemove', $('.slick-slide').index(slideIndex));
  $(".save-button[for="+filterID+"] .hidden").append(saveStuff);
});

$(".save-button button").on("click", function() {
  var undoFor = $(this).parent().attr("for");
  var savedHTML = $(this).parent().find(".hidden").html();
  $('.box').slick('slickAdd','<div class="item" for='+undoFor+'>'+savedHTML+'</div>');
  $(this).parent().find(".hidden").empty();
});*/
/*tabbing end*/

if($('.design-training-slider-test').length){
	   $('.design-training-slider-test').slick({
		  dots: false,
		  slide: '.slick-slideshow__slide',
		  slidesToShow: 4,
		  slidesToScroll: 1,
		  arrows: true,
		  autoplay: false,
		  infinite: false,
		  centermode: true,
		  centerPadding: '150px',
		  prevArrow: '<button class="slide-arrow prev-arrow arrow_trainers"></button>',
		  nextArrow: '<button class="slide-arrow next-arrow arrow_trainers"></button>',
		  responsive: [
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: false,
				dots: false,
				arrows: true,
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				 arrows: true,
				 dots: false,
				 infinite: false,
				 centerPadding: '50px',
			  }
			},    
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		  ]
		});
		
}
function getTotalSlides(selector){
	return $(selector).find('.slick-slide').not('.slick-cloned').length;
}
$('.design-training-slider-test').on('init', function(event, slick, currentSlide, nextSlide){
  //console.log('init');
  //slick.slickGoTo(1);
  var slidesToShow = slick.slickGetOption('slidesToShow');
  var totalSlides = getTotalSlides('.design-training-slider-test');
  //console.log(slidesToShow+ " "+ totalSlides);
  if(slidesToShow < totalSlides){
  	$(".design-training-slider-test > .slick-list > .slick-track").addClass("startingposition");
  }
  updateSliderCounterTryout('.design-training-slider-test');
});
$('.design-training-slider-test').on('afterChange', function(event, slick, currentSlide, nextSlide){
  //console.log(slick.slideCount);
  var slidesToShow = slick.slickGetOption('slidesToShow');
  var totalSlides = slick.slideCount;
  if(currentSlide == 0 && slidesToShow < totalSlides){
  	//console.log('ja');
	$(".design-training-slider-test > .slick-list > .slick-track").addClass("startingposition");
  }else{
	$(".design-training-slider-test > .slick-list > .slick-track").removeClass("startingposition");
  	//console.log('nee');
  }
});



/*for design-training-slider  start*/
var $carousel = $('.design-training-slider');

var settings = {
  dots: false,
  arrows: false,
  slide: '.slick-slideshow__slide',
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  //autoplay: true,
  //autoplaySpeed: 5000,
  centerPadding: '150px',
  prevArrow: '<button class="slide-arrow prev-arrow arrow_trainers"></button>',
  nextArrow: '<button class="slide-arrow next-arrow arrow_trainers"></button>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
         arrows: false,
         dots: true,
         infinite: false,
         centerPadding: '50px',
      }
    },    
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};

function setSlideVisibility() {
  //Find the visible slides i.e. where aria-hidden="false"
  var visibleSlides = $carousel.find('.slick-slideshow__slide[aria-hidden="false"]');
  //Make sure all of the visible slides have an opacity of 1
  $(visibleSlides).each(function() {
    $(this).css('opacity', 1);
  });

  //Set the opacity of the first and last partial slides.
  $(visibleSlides).first().prev().css('opacity', 1);
}

$carousel.slick(settings);
$carousel.slick('slickGoTo', 1);
setSlideVisibility();

$carousel.on('afterChange', function() {
  setSlideVisibility();
});

$('.design-training-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
  if(currentSlide == 2 || currentSlide == 0){
    $(".design-training-slider > .slick-list > .slick-track > .slick-slide").addClass("slideschangedopleiding");
    $(".design-training-slider > .slick-list > .slick-track").addClass("slideschangedopleiding2");
  }
  /*if(currentSlide == 1){
    $(".slick-slide").removeClass("slideschangedopleiding");
  }*/
});

/*design-training-slider end*/

/*for design-training-slider  start*/
var $carousel = $('.design-training-slider-fullwidth');

var settings = {
  dots: false,
  arrows: false,
  slide: '.slick-slideshow__slide',
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 5000,
  centerMode: true,  
  centerPadding: '150px',
  prevArrow: '<button class="slide-arrow prev-arrow"></button>',
  nextArrow: '<button class="slide-arrow next-arrow"></button>',
  responsive: [
    {
      breakpoint: 1542,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
         arrows: false,
         dots: false,
         centerPadding: '50px',
      }
    },  
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
         arrows: false,
         dots: false,
         centerPadding: '50px',
      }
    },  
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};

function setSlideVisibility() {
  //Find the visible slides i.e. where aria-hidden="false"
  var visibleSlides = $carousel.find('.slick-slideshow__slide[aria-hidden="false"]');
  //Make sure all of the visible slides have an opacity of 1
  $(visibleSlides).each(function() {
    $(this).css('opacity', 1);
  });

  //Set the opacity of the first and last partial slides.
  $(visibleSlides).first().prev().css('opacity', 1);
}

$carousel.slick(settings);
$carousel.slick('slickGoTo', 1);
setSlideVisibility();

$carousel.on('afterChange', function() {
  setSlideVisibility();
});

/*design-training-slider end*/

/*for design-training-slider  start*/
var $carousel = $('.online-training-slider');

var settings = {
  dots: false,
  arrows: false,
  slide: '.slick-slideshow__slide',
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 5000,
  centerMode: true,  
  centerPadding: '150px',
  prevArrow: '<button class="slide-arrow prev-arrow"></button>',
  nextArrow: '<button class="slide-arrow next-arrow"></button>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
         arrows: false,
         dots: true,
         centerPadding: '50px',
      }
    },  
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};

function setSlideVisibility() {
  //Find the visible slides i.e. where aria-hidden="false"
  var visibleSlides = $carousel.find('.slick-slideshow__slide[aria-hidden="false"]');
  //Make sure all of the visible slides have an opacity of 1
  $(visibleSlides).each(function() {
    $(this).css('opacity', 1);
  });

  //Set the opacity of the first and last partial slides.
  $(visibleSlides).first().prev().css('opacity', 1);
}

$carousel.slick(settings);
$carousel.slick('slickGoTo', 1);
setSlideVisibility();

$carousel.on('afterChange', function() {
  setSlideVisibility();
});

/*design-training-slider end*/

/*Wij-trainers-slider start*/

var $carousel = $('.Wij-trainers-slider');

var settings = {
  dots: false,
  arrows: false,
  infinite: false,
  slide: '.slick-slideshow__slide',
  slidesToShow: 4, 
  arrows: true,
  autoplay: false,
  //autoplay: true,
  //autoplaySpeed: 5000,
  centerPadding: '100px',
  prevArrow: '<button class="slide-arrow prev-arrow arrow_trainers"></button>',
  nextArrow: '<button class="slide-arrow next-arrow arrow_trainers"></button>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        arrows: false,
        centerPadding: '50px',
        dots: true
      }
    },    
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};

function setSlideVisibility() {
  //Find the visible slides i.e. where aria-hidden="false"
  var visibleSlides = $carousel.find('.slick-slideshow__slide[aria-hidden="false"]');
  //Make sure all of the visible slides have an opacity of 1
  $(visibleSlides).each(function() {
    $(this).css('opacity', 1);
  });

  //Set the opacity of the first and last partial slides.
  $(visibleSlides).first().prev().css('opacity', 1);
}

$carousel.slick(settings);
$carousel.slick('slickGoTo', 1);
setSlideVisibility();

$carousel.on('afterChange', function() {
  setSlideVisibility();
});


$('.Wij-trainers-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
  if(currentSlide == 2 || currentSlide == 0){
    $(".Wij-trainers-slider > .slick-list > .slick-track > .slick-slide").addClass("slideschanged");
    $(".Wij-trainers-slider > .slick-list > .slick-track").addClass("slideschanged2");
  }
  /*
  if(currentSlide == 1){
    $(".slick-slide").removeClass("slideschanged");
  }*/
});

if($( ".Wij-trainers-slider" ).children().children().children().last().attr("data-slick-index") == 3){
  $( ".Wij-trainers-slider" ).children().children().css("margin-left","1vw");
}
/*Wij-trainers-slider end*/

/*Why-cf slider start*/

var $carousel = $('.Why-cf-slider');

var settings = {
  dots: false,
  infinite: false,
  slide: '.slick-slideshow__slide',
  slidesToShow: 4, 
  arrows: true,
  autoplay: false,
  //autoplay: true,
  //autoplaySpeed: 5000,
  centerPadding: '500px',
  prevArrow: '<button class="slide-arrow prev-arrow arrow_trainers"></button>',
  nextArrow: '<button class="slide-arrow next-arrow arrow_trainers"></button>',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2.2,
        dots: false,
        arrows: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.2,
        arrows: true,
        centerPadding: '100px',
        dots: false
      }
    },    
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};

function setSlideVisibility() {
  //Find the visible slides i.e. where aria-hidden="false"
  var visibleSlides = $carousel.find('.slick-slideshow__slide[aria-hidden="false"]');
  //Make sure all of the visible slides have an opacity of 1
  $(visibleSlides).each(function() {
    $(this).css('opacity', 1);
  });

  //Set the opacity of the first and last partial slides.
  $(visibleSlides).first().prev().css('opacity', 1);
}

$carousel.slick(settings);
$carousel.slick('slickGoTo', 1);
setSlideVisibility();

$carousel.on('afterChange', function() {
  setSlideVisibility();
});


$('.Why-cf-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
  if(currentSlide == 2 || currentSlide == 0){
    $(".Wij-trainers-slider > .slick-list > .slick-track > .slick-slide").addClass("slideschanged");
    $(".Wij-trainers-slider > .slick-list > .slick-track").addClass("slideschanged2");
  }
  /*
  if(currentSlide == 1){
    $(".slick-slide").removeClass("slideschanged");
  }*/
});

if($( ".Why-cf-slider" ).children().children().children().last().attr("data-slick-index") == 3){
  $( ".Why-cf-slider" ).children().children().css("margin-left","1vw");
}
/*deze-voor-slider start*/

/*deze-voor-slider end*/

/*direct-ux-body slider start*/

 $('.direct-ux-slider').slick({
        dots: false,
        arrows: false,     
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        utoplay: true,
        autoplaySpeed: 5000,
        centerMode: true,
        centerPadding: '0px',
         responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
               arrows: false,  
               dots: true             
            }
          },    
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
});

/*direct-ux-body slider end */


/*for slider counter*/
var $slider = $('.slider');

if ($slider.length) {
  var currentSlide;
  var slidesCount;
  var sliderCounter = document.createElement('div');
  sliderCounter.classList.add('slider__counter');
  
  var updateSliderCounter = function(slick, currentIndex) {
    currentSlide = slick.slickCurrentSlide() + 1;
    //slidesCount = slick.slideCount;
    slidesCount = $slider.find('.slick-slide').not('.slick-cloned').length;
    $(sliderCounter).text(currentSlide + '/' + slidesCount)
  };

  $slider.on('init', function(event, slick) {
    $slider.append(sliderCounter);
    updateSliderCounter(slick);
  });

  $slider.on('afterChange', function(event, slick, currentSlide) {
    updateSliderCounter(slick, currentSlide);
  });
  
  $slider.on('slickUnfilter', function(event, slick, currentSlide) {
    updateSliderCounter(slick, currentSlide);
  });

  $slider.slick();
  
}

/*for accordian start*/

/*to get the string name of the clicked menu parent in the mobile drilldown menu, not in use now*/
$('.catogorietekstA').each(function(){
  var backTxt = $(this).parent().parent().parent().closest('.is-drilldown-submenu-parent').find('> a').text();
  $(this).text(backTxt);
});

/*
const collection = document.getElementsByClassName("Tabindexcheck");
for (let i = 0; i < collection.length; i++) {
  if (!collection[i].hasAttribute("tabindex")) { 
  collection[i].classList.add("HeeftGeenTabindex");
  }
}*/
});

if ($(window).width() < 767 ){
  $( "#beoordelingevaluatie" ).text("Jouw beoordeling: (scroll links op mobiel)");
}

$('.cursistenreview__photocarousel').slick({
  dots: false,
  arrows: true,    
  slidesToShow: 2.2,
  slidesToScroll: 1,
  autoplay:false,
  adaptiveHeight: false,
  infinite: false,
  prevArrow: '<button class="slide-arrow prev-arrow arrow_trainers"></button>',
  nextArrow: '<button class="slide-arrow next-arrow arrow_trainers"></button>',
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 1.6,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,        
      }
    },    
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

function getTotalSlides(selector){
	return $(selector).find('.slick-slide').not('.slick-cloned').length;
}
$('.cursistenreview__photocarousel').on('init', function(event, slick, currentSlide, nextSlide){
  //console.log('init');
  //slick.slickGoTo(1);
  var slidesToShow = slick.slickGetOption('slidesToShow');
  var totalSlides = getTotalSlides('.cursistenreview__photocarousel');
  //console.log(slidesToShow+ " "+ totalSlides);
  if(slidesToShow < totalSlides){
  	$(".cursistenreview__photocarousel > .slick-list > .slick-track").addClass("startingposition");
  }
  updateSliderCounterTryout('.cursistenreview__photocarousel');
});
$('.cursistenreview__photocarousel').on('afterChange', function(event, slick, currentSlide, nextSlide){
  //console.log(slick.slideCount);
  var slidesToShow = slick.slickGetOption('slidesToShow');
  var totalSlides = slick.slideCount;
  if(currentSlide == 0 && slidesToShow < totalSlides){
  	//console.log('ja');
	$(".cursistenreview__photocarousel > .slick-list > .slick-track").addClass("startingposition");
  }else{
	$(".cursistenreview__photocarousel > .slick-list > .slick-track").removeClass("startingposition");
  	//console.log('nee');
  }
});

$("#trainersbox").children().addClass('trainersbox_elements');
var numItems = $('.trainersbox_elements').length
var test1 = 1;
var test2 = 2;
while(numItems >= test2){
    $(".trainersbox_elements:nth-child("+test1+")").append($(".trainersbox_elements:nth-child("+test2+")"));
    var p = $(".trainersbox_elements:nth-child("+test1+")");
    p.replaceWith('<p>' + p.html() + '</p>');
    var t = $("span.trainersbox_elements:nth-child("+test1+")");
    t.replaceWith('<br><span>' + t.html()+'</span>');
    test1 = test2;
    test2 +=1;
}

$(".doorway_content_item").children().addClass('doorway_content_item_child');
$(".doorway_content_item_child").children().addClass('doorway_content_item_baby');
$(".doorway_content_item_baby:nth-child(1)").addClass('large-5').removeClass('large-7');
$(".doorway_content_item_baby:nth-child(2)").addClass('large-7').removeClass('large-5');

$( ".TopbarMobileOffcanvasNav" ).find(".close-button").on( "click", function() {
  $( ".account-dropdown-mobile" ).find(".close-button").click();
} );

$( document ).ready(function() {
  $('.js-off-canvas-overlay').eq(1).css({
    'background' : 'transparent',
    'z-index' : '9999'
 });
});