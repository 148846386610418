$(function () {
    $('.account__profile__bar__filter.show select').on('change', function () {
        var val = $(this).val();
        if(val == "alles"){
        	$(".verzameling-enkeledivs").show();
        }else{
			$(".verzameling-enkeledivs").show().filter(function () {
				return $(this).data('catid') != val;
			}).hide();
		}
    });
    
    $('.account__sidebar__section__menu.filter li').on('click', function () {
        var val = $(this).data('filter');
        $('.account__sidebar__section__menu.filter li').removeClass('current');
        //$('.account__profile__bar__filter.show select option[value="'+val+'"]').selected;
        $(this).addClass('current');
        if(val == "alles"){
        	$(".verzameling-enkeledivs").show();
        }else{
			$(".verzameling-enkeledivs").show().filter(function () {
				return $(this).data('catid') != val;
			}).hide();
		}
    });
    
    $('.account__sidebar__section__menu.view li').on('click', function () {
        $('.account__sidebar__section__menu.view li').removeClass('current');
        $(this).addClass('current');
        var val = $(this).data('view');
    	$(".verzameling-enkeledivs .row").hide();
    	$(".verzameling-enkeledivs ."+val).show();
    });
});