$(function () {
  $("input[name='color']").on("click", function (e) {
    $("input[name='color']").parent().find('img').css('border','none');
    $(this).parent().find('img').css('border','2px solid #FF9900');
    $(this).parent().find('img').css('border-radius','100%');

  });
  // Get the element that will trigger the file selection
    var triggerElement = document.getElementById('selfimage'); // Replace 'triggerElementId' with your actual element's ID

    // Get the file input element
    var fileInput = document.getElementById('fileToUpload');
  if(triggerElement){
    // Add a click event listener to the trigger element
    triggerElement.addEventListener('click', function() {
        // Trigger the file input click event
        fileInput.click();
        $("input[name='color']").prop('checked', false);
        $("input[name='color']").parent().find('img').css('border','none');
    });
  }
  if(fileInput){
    // Add a change event listener to the file input
    fileInput.addEventListener('change', function(event) {
        // Assuming you have a form with id 'uploadForm'
        var form = document.getElementById('form-profiel');
        var formData = new FormData(form);
        var xhr = new XMLHttpRequest();
        // Select the #selfimage element
        var selfImageElement = document.getElementById('selfimage');

        // Get the value of the data-userid attribute
        var userId = selfImageElement.getAttribute('data-userid');

        // Get the action attribute from the form
        var actionUrl = form.action;
        
        // Get the selected files
        var files = event.target.files;
        if (files.length > 0) {
            var file = files[0];
            // Get the name of the file
            var fileName = file.name;

            // Split the file name by dot (.) and get the last part
            var fileExtension = fileName.split('.').pop();
        }
        xhr.open('POST', actionUrl, true);

        xhr.onload = function () {
            if (xhr.status === 200) {
                // File(s) uploaded.
                //alert('Upload complete!'+userId);

                // Create a new div element
                  var newDiv = document.createElement('div');
                  var newDivhomepage = document.createElement('div');
                  // Set the style of the new div
                  newDiv.style.backgroundImage = "url('/images/profile-images/"+userId+"_profielfoto."+fileExtension+ '?' + new Date().getTime();"')";
                  newDiv.style.backgroundSize = "contain";
                  newDiv.style.width = "48px";
                  newDiv.style.height = "48px";
                  newDiv.style.borderRadius = "100%";
                  newDiv.classList.add("changedimg");
                  if($('.profile-avatar-field').length){
                    newDivhomepage.classList.add("hoverprofileimg");
                  }
                  // Clear current child elements from #selfimage
                  while (selfImageElement.firstChild) {
                      selfImageElement.removeChild(selfImageElement.firstChild);
                  }
  
                  // Append the new div to #selfimage
                  selfImageElement.appendChild(newDiv);
                  if($('.profile-avatar-field').length){
                    newDiv.appendChild(newDivhomepage);
                  }
                
            } else {
                alert('Upload error. Try again.');
            }
        };

        xhr.send(formData);
    
      });
    }
  
});

$("#form-addinginterests").on('click', function(){
  var $boxes = $("#form-addinginterests").children();
  $boxes.each(function(){
    $ischecked = $(this).children().is(":checked");
    if($ischecked == true){
      $(this).removeClass('interestnotselected');
      $(this).removeClass('interestselected');
      $(this).addClass('interestselected');
    }else{
      $(this).removeClass('interestnotselected');
      $(this).removeClass('interestselected');
      $(this).addClass('interestnotselected');
    }
  });
});


