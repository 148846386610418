$(function () {
    $('#opleidingsbudget')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();

            $.post(form.attr("action"), form.serialize(), function (response) {
                $('#opleidingsbudget-done').foundation('open');
            	dataLayer.push({'event': 'OpleidingsbudgetFormSubmit'});
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        })
});