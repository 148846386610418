$(function () {
if($('.deze-voor-box-body').length){
	var slides = 3;
	if($('input[name="slides"]').length){
		slides = parseInt($('input[name="slides"]').val());
	}
    $('.deze-voor-box-body').slick({
        dots: false,
        arrows: true,     
        slidesToShow: slides,
        slidesToScroll: slides,
        autoplay: true,
        autoplaySpeed: 5000,
        centerMode: true,
        centerPadding: '0px',
         responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
				centerMode: true,
				centerPadding: '30px',
                slidesToShow: 1,
                slidesToScroll: 1,
               arrows: true,  
               dots: true             
            }
          },    
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
	});
	
}

});