
$(function () {
    $('.breadcrumbs li:not(.last):not(.home)').hover(function (e) {
    		$(this).find('.visible').stop(true,true).toggle('1000');
    		$(this).find('.hidden').stop(true,true).toggle('1000');
            //$('.breadcrumbs .visible').stop(true,true).toggle('1000');
            //$('.breadcrumbs .hidden').stop(true,true).toggle('1000');
			$('.breadcrumbs li.last .visible').stop(true,true).toggle('1000');
			$('.breadcrumbs li.last .hidden').stop(true,true).toggle('1000');
        });
});