/*var decodeEntities = (function() {
	// this prevents any overhead from creating the object each time
	var element = document.createElement('div');
  
	function decodeHTMLEntities (str) {
	  if(str && typeof str === 'string') {
		// strip script/html tags
		str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
		str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
		element.innerHTML = str;
		str = element.textContent;
		element.textContent = '';
	  }
  
	  return str;
	}
  
	return decodeHTMLEntities;
  })();*/

$(function () {
	if(!window.location.hostname.includes('competencefactory')){
	var termTemplate = "<span class='ui-autocomplete-term'>%s</span>";
	$( "#ac,#acm,#acmaatwerk" ).autocomplete({
	  source: function(request, response) {
	    var searchquery = $('#ac').val();
	    if(searchquery == ""){
	    	searchquery = $('#search-mobile .search-form__input').val();
			if(searchquery == ""){
				searchquery = $('#acmaatwerk').val();
			}
	    }
    	$.getJSON("/zoeken/autocomplete", { q: searchquery }, response);
	  },
	  minLength: 1,
	  open: function (e, ui) {
				var acData = $(this).data('ui-autocomplete');
				acData
				.menu
				.element
				.find('li > div > span.title')
				.each(function () {
					var me = $(this);
					var keywords = acData.term.split(' ').join('|');
					me.html(me.text().replace(new RegExp("(" + keywords + ")", "gi"), '<b>$1</b>'));
				 });
				 acData.menu.element.find('div.search-form__autocomplete__button button').removeClass('ui-menu-item-wrapper');
				 acData.menu.element.find('div.search-form__autocomplete__button a').removeClass('ui-menu-item-wrapper');
				 acData.menu.element.find('div.search-form__autocomplete__button, div.search-form__autocomplete__subtitle').removeClass('ui-menu-item');
	},
     
     
      
	  select: function(event, ui) {
	  	if($('#ac').val() != ""){
	  		//$('#ac').val(decodeEntities(ui.item.value));
	    }else{
	  		//$('#acm').val(decodeEntities(ui.item.value));
	    }
		if($('#acmaatwerk').length){
			
		}else{
			window.location.href = ui.item.url;
		}
	  },
	  create: function () {
            $(this).data('ui-autocomplete')._renderItem = function (ul, item) {
                 return $('<li class="search-form__autocomplete__suggestion">')
                    .append('<div><span class="title">'+item.value+'</span><span class="type">' + item.type + '</span></div>')
                    .appendTo(ul); // customize your HTML
            };
            $(this).data('ui-autocomplete')._renderMenu = function (ul, items) {
                var that = this;
				  $.each( items, function( index, item ) {
					that._renderItemData( ul, item );
				  });
				  $( ul ).prepend('<div class="search-form__autocomplete__subtitle">Suggesties</div>');
				  $( ul ).append('<div class="search-form__autocomplete__button"><button class="cf-button--blue"><span class="cf-button__text">Bekijk alle resultaten</span><span class="cf-button__icon"><i class="fas fa-arrow-right"></i></span></button></div>');
            };
        }
	  
	});
	$('body').on('click', '.search-form__autocomplete__button button', function(){
		if($('#search-desktop .search-form__input.ui-autocomplete-input').val() != ""){
			$('#search-desktop form').submit();
		}else{
			$('#search-mobile form').submit();
		}
		
	});
	}
});