jQuery.expr.filters.offscreen = function(el) {
    var rect = el.getBoundingClientRect();
    return (
             (rect.x + rect.width) < 0 
               || (rect.y + rect.height) < 0
               || (rect.x > window.innerWidth || rect.y > window.innerHeight)
           );
  };


function slide(direction, sliderElement) {
    let left;
    var content = sliderElement.querySelector('.rf-cards-scroller-content');
    var slide = content.querySelector('.snap-slide');
    const { scrollLeft, clientWidth } = content;
    console.log('sliding');
    
    console.log(scrollLeft);
    console.log(clientWidth);
    switch (direction) {
        case "prev":
        left = scrollLeft - slide.offsetWidth;
        break;
        case "next":
        default:
        left = scrollLeft + slide.offsetWidth;
        break;
    }

    content.scroll({
        left,
        behavior: "smooth"
    });
}

const sliderObserver = new IntersectionObserver(
(entries) => {
    entries.forEach((entry) => {
    var slider = entry.target.parentElement.parentElement.parentElement.parentElement;
    var navigation = slider.querySelector('.paddlenav');
    if (entry.isIntersecting) {
        //console.log("is in view: true");
        //console.log(entry);
        if(entry.target.classList.contains("first")){
            navigation.querySelector('.paddlenav-arrow-previous').disabled = true;
            navigation.querySelector('.paddlenav-arrow-previous').setAttribute('aria-hidden', true);
            //navigation.querySelector('.paddlenav-arrow-next').disabled = false;
        }else if(entry.target.classList.contains("last")){
            navigation.querySelector('.paddlenav-arrow-next').disabled = true;
            navigation.querySelector('.paddlenav-arrow-next').setAttribute('aria-hidden', true);
        }
    } else {
        //console.log("is in view: false");
        if(entry.target.classList.contains("first")){
            navigation.querySelector('.paddlenav-arrow-previous').disabled = false;
            navigation.querySelector('.paddlenav-arrow-previous').setAttribute('aria-hidden', false);
            //navigation.querySelector('.paddlenav-arrow-next').disabled = false;
        }else if(entry.target.classList.contains("last")){
            navigation.querySelector('.paddlenav-arrow-next').disabled = false;
            navigation.querySelector('.paddlenav-arrow-next').setAttribute('aria-hidden', false);
        }
    }
    });
},
{ threshold: 0.9 }
);

let sliders = document.querySelectorAll('.snap-slider');
for (let slider of sliders) {
    var firstslide = slider.querySelector(".snap-slide:first-of-type");
    var lastslide = slider.querySelector(".snap-slide:last-of-type");
    var prevButton = slider.querySelector(".paddlenav .paddlenav-arrow-previous");
    var nextButton = slider.querySelector(".paddlenav .paddlenav-arrow-next");
    if (prevButton && nextButton) {
        prevButton.addEventListener("click", () => slide("prev", slider));
        nextButton.addEventListener("click", () => slide("next", slider));
    }
    if(firstslide !== null){
        sliderObserver.observe(firstslide);
    }
    if(lastslide !== null){
        sliderObserver.observe(lastslide);
    }
}


$(function () {
    //console.log($('.rf-cards-scroller-itemview').is(':offscreen'));
    //console.log($('.rf-cards-scroller-itemview:first-of-type').is(':offscreen'));

});