$(function () {
    $('.readmore-link').click(function(e){
    	if($('.readmore-text').length > 0){
			$('.readmore-text').toggle();
		}
		var $div = $(this).prev();
  		var h = $div.find('p').height();
		if($(this).text() != "Bekijk de actievoorwaarden"){
			if($(this).text() == "Lees meer"){
				if($(this).parent().hasClass('inner')){
					$(this).prev().animate({height:h},200);
				}
				$(this).text('Lees minder');
			}else{
				if($(this).parent().hasClass('inner')){
					$(this).prev().animate({height:75},200);
				}
				$(this).text('Lees meer');
			}
		}
	});
});