$(function () {
	const body = document.body;
	const nav = document.querySelector(".row.sticky");
	const menu = document.querySelector(".row.sticky .header__main-menu");
	const scrollUp = "scroll-up";
	const scrollDown = "scroll-down";
	const scrollSubmenu = "scroll-submenu";
	const scrollClose = "scroll-header";
	let lastScroll = 0;

	window.addEventListener("scroll", function(){
	  const currentScroll = window.pageYOffset;
	  
	  
	  if (currentScroll <= 0) {
		body.classList.remove(scrollSubmenu);
	  }else{
		body.classList.add(scrollSubmenu);
	  }
	  
	  if (currentScroll <= 400) {
		body.classList.add(scrollClose);
	  }else{
		body.classList.remove(scrollClose);
	  }
	  
	  if (currentScroll <= 600) {
		body.classList.remove(scrollUp);
		return;
	  }else{
		body.classList.remove(scrollSubmenu);
	  }
	  
	  
	  

	  if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
		// down
		body.classList.remove(scrollUp);
		body.classList.add(scrollDown);
	  } else if (
		currentScroll < lastScroll &&
		body.classList.contains(scrollDown)
	  ) {
		// up
		body.classList.remove(scrollDown);
		body.classList.add(scrollUp);
	  }
	  lastScroll = currentScroll;
	});

	const trainingNavbar = document.querySelector('.training-bottom-navbar');
  
	function addMouseEnterClass() {
		trainingNavbar.classList.add('mouseenter');
	}
	function removeMouseEnterClass(event) {
		if (!trainingNavbar.contains(event.target)) {
			trainingNavbar.classList.remove('mouseenter');
		}
	  }
	if(trainingNavbar){
		trainingNavbar.addEventListener('mouseenter', addMouseEnterClass);
		trainingNavbar.addEventListener('mouseleave	', removeMouseEnterClass);
		trainingNavbar.addEventListener('click', addMouseEnterClass);
		document.addEventListener('click', removeMouseEnterClass);
	}
});

//for mobile header
$(window).on('load', function() {
	if($( ".header__mobile" ).children(":first").length ){
		
		$(window).on('scroll', function() {
			if($(".training__actiebalk")[0] && window.scrollY < 100){
				$( ".header__mobile" ).children(":first").addClass('is-anchored');
				$( ".header__mobile" ).children(":first").removeClass('is-at-top');
				$( ".header__mobile" ).children(":first").removeClass('is-stuck');
			}else if(window.scrollY == 0){
				$( ".header__mobile" ).children(":first").addClass('is-anchored');
				$( ".header__mobile" ).children(":first").removeClass('is-at-top');
				$( ".header__mobile" ).children(":first").removeClass('is-stuck');
			}else{
				$( ".header__mobile" ).children(":first").removeClass('is-anchored');
				$( ".header__mobile" ).children(":first").addClass('is-at-top');
				$( ".header__mobile" ).children(":first").addClass('is-stuck');
				$( ".header__mobile" ).children(":first").css("top", "0px");
			}
		});	
	}
});