$(function () {
  $(".account__sidebar__section__menu__submenu__title").on("click", function (e) {
    if($(this).parent().hasClass('closed')){
        $(this).parent().addClass('open');
        $(this).parent().removeClass('closed');
        $(this).parent().find('ul').show();
    }else{
        $(this).parent().addClass('closed');
        $(this).parent().removeClass('open');
        $(this).parent().find('ul').hide();
    }
  });
});
