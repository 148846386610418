/**
 * keuzehulp.js
 */
$(function () {
    
    $('.interestselection').on('click', function() {
    	if($('.interestselection').is(':checked')){
        	$('#interestbox').show();
    	}else{
        	$('#interestbox').hide();
    	}
    });

});