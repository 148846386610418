$(function () {
    // JavaScript to hide sticky element on scroll
    const header = document.querySelector('.account__profile__menu'); // Adjust this selector to your element
    const title = document.querySelector('.account__profile__menu__header'); 
    const sentinel = document.createElement('div');
    if(title){
        // Function to handle intersection changes
        function handleIntersection(entries) {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    title.classList.add('hide');
                } else {
                    title.classList.remove('hide');
                }
            });
        }

        // Create an IntersectionObserver instance
        const observer = new IntersectionObserver(handleIntersection);

        // Add a sentinel element before your sticky element in the HTML structure
        header.parentNode.insertBefore(sentinel, header);

        // Start observing the sentinel
        observer.observe(sentinel);
    }
});