
if($('.cf-proof').length > 0){
	var run = false;
	$(window).scroll(function() {
		var top_of_element = $(".cf-proof").offset().top;
		var bottom_of_element = $(".cf-proof").offset().top + $(".cf-proof").outerHeight();
		var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
		var top_of_screen = $(window).scrollTop();

		if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element) && run == false){
			$('.count').each(function () {
				$(this).prop('Counter',0).animate({
					Counter: $(this).text()
				}, {
					duration: 4000,
					easing: 'swing',
					step: function (now) {
						$(this).text(Math.ceil(now));
					}
				});
			});
			run = true;
		}
	});
}