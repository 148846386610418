$(function () {
    /*$('#maatwerk')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();

            $.post(form.attr("action"), form.serialize(), function (response) {
                $('#maatwerk-done').foundation('open');
            	$('#maatwerk').trigger("reset");
            	dataLayer.push({'event': 'MaatwerkFormSubmit'});
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        })*/
});