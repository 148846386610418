$(function () {
    $('.step-module__timeline__item')
        .on('click', function (e) {
            var step = $(this).data('step');
            var module = $(this).parent().parent().data('id');
			$('.step-module[data-id="'+module+'"] .step-module__timeline__item').removeClass('filled');
			$('.step-module[data-id="'+module+'"] .step-module__section').hide();
			$('.step-module[data-id="'+module+'"] .step-module__section').removeClass('current');
			$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+step+'"]').show();
			$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+step+'"]').addClass('current');
			for(let i=1;i<step+1;i++){
				$('.step-module[data-id="'+module+'"] .step-module__timeline__item[data-step="'+i+'"]').addClass('filled');
			}
            var prevstep = step - 1;
            if($('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+prevstep+'"]').length){
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="previous"]').show();
            }else{
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="previous"]').hide();
			}
            var nextstep = step + 1;
            if($('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+nextstep+'"]').length){
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="previous"]').show();
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="next"]').show();
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="submit"]').hide();
			}else{
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="next"]').hide();
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="submit"]').show();
			}
        });
        
    $('.step-module__buttons button[data-action="previous"]')
        .on('click', function (e) {
        	e.preventDefault();
            var module = $(this).parent().parent().data('id');
            
            if(module == undefined){
            	module = $(this).parent().parent().parent().data('id');
            }
            var step = $('.step-module[data-id="'+module+'"] .step-module__section.current').data('step');
            var prevstep = step - 1;

			if(prevstep < 2){
				$('.fullscreenform__footer').show();
			}
    		checkProgress(prevstep, module);
            if($('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+prevstep+'"]').length){
				$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+step+'"]').removeClass('slidein');
				$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+step+'"]').addClass('slideout').delay(500).removeClass('current').hide(1);
				//$('.step-module[data-id="'+module+'"] .step-module__section').delay(1000).removeClass('current');
				//$('.step-module[data-id="'+module+'"] .step-module__section').delay(1000).hide();
				$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+prevstep+'"]').removeClass('slideout');
				$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+prevstep+'"]').delay(500).show(1).addClass('slidein');
				//$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+prevstep+'"]');
				$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+prevstep+'"]').addClass('current');
				$('.step-module[data-id="'+module+'"] .step-module__timeline__item[data-step="'+step+'"]').removeClass('filled');
				$('.step-module__buttons button:not(.first)[data-action="next"]').show();
				var prevprevstep = prevstep - 1;
				if(!$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+prevprevstep+'"]').length){
					$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="previous"]').toggle();
					$('.step-module__buttons button.first[data-action="next"]').show();
					$('.step-module__buttons button:not(.first)[data-action="next"]').hide();
					console.log('checkpoint4');
				}
				console.log($('.step-module__buttons button.first[data-action="next"]').length);
				console.log('checkpoint');
				if($('.step-module__buttons button.first[data-action="next"]').length){
				console.log('checkpoint2');
				}else{
					$('.step-module[data-id="'+module+'"] .step-module__buttons button:not(.first)[data-action="next"]').show();
				console.log('checkpoint3');
				}
				dataLayer.push({

					'event': 'test interaction previous',
					'step': step

				});
				dataLayer.push({

					'event': 'test interaction',
					'step': step

				});
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="submit"]').hide();
			}else{
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="previous"]').hide();
				$('.step-module__buttons button.first[data-action="next"]').show();
			}
			
        });
    $('.step-module__buttons button[data-action="next"]')
        .on('click', function (e) {
        	e.preventDefault();
            var module = $(this).parent().parent().data('id');
            //console.log(module);
            if(module == undefined){
            	module = $(this).parent().parent().parent().data('id');
            }
            var step = $('.step-module[data-id="'+module+'"] .step-module__section.current').data('step');
            var nextstep = step + 1;
			if(nextstep > 1){
				$('.fullscreenform__footer').hide();
			}
			//Check if current step also has an action that needs to be performed
            if($('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+step+'"]').find('input[name="action"]').length){
            	//Get action, perform AJAX call
            	var action = $('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+step+'"]').find('input[name="action"]').val();
            	if(action == "/trainingen/getsubcategories"){
            		if($('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+step+'"]').find('input.vakgebied:checked').length < 1){
            			alert('Maak een keuze alsjeblieft!');
            		}else{
    				//var vakgebied = $('.step-module__section[data-step="'+step+'"]').find('input.vakgebied').serialize();
    				//alert(vakgebied);
					$.post( action, $('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+step+'"]').find('input.vakgebied').serialize())
					  .done(function( data ) {
					  		//alert(data);
							var items = "";
							$.each(data, function (i, item) {
								//alert(item);
								items += "<div class='category'><input type=\"checkbox\" value=\"" + item.id + "\" name=\"onderwerp[]\" class=\"onderwerp\" /><p>" + item.title + "</p></div>";
							});
							$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+nextstep+'"] .step-module__section__choices').html(items);
            				goToNextStep(nextstep, module);
					  })
					  .fail(function() {
						alert( "error" );
					  });
					}
				}
            }else{
				var inputfields = $('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+step+'"]').find('input');
				if(inputfields.length){
					var continueToStep = true;
					inputfields.each(function(item){
						if($( this ).prop('required')){
							if($(this).val() == ""){
								continueToStep = false;
							}
						}
					});
					if(continueToStep){
						goToNextStep(nextstep, module);
					}else{
						alert("Vul de verplichte veld(en) in.");
					}
				}else{
					//alert('Maak een keuze alsjeblieft!');
            		goToNextStep(nextstep, module);
				}
            }
            
        });
    function checkProgress(nextstep, module){
    	if($('.fullscreenform__progress').length){
    		var steps = $('.step-module[data-id="'+module+'"] .step-module__section').length;
    		var stepstaken = nextstep;
    		var progress = (100 / steps) * (stepstaken-1);
    		if($('.fullscreenform__progress__current').length){
    			$('.fullscreenform__progress__current').css('width', progress+'%');
    		}
    	}
    	
    }
    function goToNextStep(nextstep, module){
    	checkProgress(nextstep, module);
    	var step = nextstep - 1;
    	if($('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+nextstep+'"]').length){
    		$('.step-module__buttons button.first[data-action="next"]').hide();
    		$('.step-module__buttons button:not(.first)[data-action="next"]').show();
			$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+step+'"]').removeClass('slidein');
			$('.step-module[data-id="'+module+'"] .step-module__section').removeClass('current');
			$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+step+'"]').addClass('slideout').delay(300).hide(1);
			//$('.step-module[data-id="'+module+'"] .step-module__section').delay(300).hide(1);
			$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+nextstep+'"]').removeClass('slideout');
			$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+nextstep+'"]').delay(300).show(1);
			$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+nextstep+'"]').addClass('slidein');
			$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+nextstep+'"]').addClass('current');
			$('.step-module[data-id="'+module+'"] .step-module__timeline__item[data-step="'+nextstep+'"]').addClass('filled');
			var nextnextstep = nextstep + 1;
			if(!$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+nextnextstep+'"]').length){
				$('.step-module[data-id="'+module+'"] .step-module__buttons button:not(.first)[data-action="next"]').toggle();
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="submit"]').toggle();
			}
			$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="previous"]').show();
			dataLayer.push({

				'event': 'test interaction',
				'step': step

			});
		}
    }
    $('body')
        .on('click', '.step-reset', function (e) {
            var step = 1;
            var module = $(this).data('id');
			$('.step-module[data-id="'+module+'"] .step-module__timeline__item').removeClass('filled');
			$('.step-module[data-id="'+module+'"] .step-module__section').hide();
			$('.step-module[data-id="'+module+'"] .step-module__section').removeClass('current');
			$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+step+'"]').show();
			$('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+step+'"]').addClass('current');
			for(let i=1;i<step+1;i++){
				$('.step-module[data-id="'+module+'"] .step-module__timeline__item[data-step="'+i+'"]').addClass('filled');
			}
            var prevstep = step - 1;
            if($('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+prevstep+'"]').length){
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="previous"]').show();
            }else{
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="previous"]').hide();
			}
            var nextstep = step + 1;
            if($('.step-module[data-id="'+module+'"] .step-module__section[data-step="'+nextstep+'"]').length){
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="previous"]').show();
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="next"]').show();
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="submit"]').hide();
			}else{
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="next"]').hide();
				$('.step-module[data-id="'+module+'"] .step-module__buttons button[data-action="submit"]').show();
			}
        	
			$("#inhoud").show();
			$('#noresult').hide();
			$('#result').hide();
			$('#result-related').hide();
			$('#nieuwsbrief-aanmelden').hide();
        });
    $('.clubid')
        .on('change', function (e) {
        	if(!$(this).prop("checked")){
        		$('input[name="redirect"]').val("/test-bedankt-account");
        		console.log("yaay");
        	}else{
        		$('input[name="redirect"]').val("/test-bedankt");
        		console.log("naay");
        	}
        });
    $('.step-module__buttons button[data-action="submit"]')
        .on('click', function (e) {
        	e.preventDefault();
            var module = $(this).parent().parent().data('id');
            var step = $('.step-module[data-id="'+module+'"] .step-module__section.current').data('step');
			var action = $('.step-module__form').attr('action');
			//console.log($('.step-module__form').serialize());
			//var vakgebied = $('.step-module__section[data-step="'+step+'"]').find('input.vakgebied').serialize();
			//alert(vakgebied);
			//$('.step-module__form').submit();
			$.post( action, $('.step-module__form').serialize())
			  .done(function( data ) {
					//var items = "";
					//$.each(data, function (i, item) {
						//alert(item);
					//	items += "<div><input type=\"checkbox\" value=\"" + item.id + "\" name=\"onderwerp[]\" class=\"onderwerp\" /><p>" + item.title + "</p></div>";
					//});
					$("#inhoud").hide();
					//console.log($('.step-module__form').data('redirect'));
					if($('.step-module__form').data('redirect') == true){
						var url = $('input[name="redirect"]').val();
						console.log(data);
						console.log(data.userid);
						//console.log(url);
						dataLayer.push({'email': data.email});
						dataLayer.push({'firstname': data.firstname});
						dataLayer.push({'userid': data.userid});
						var impressions = [];
						for (var i = 0; i < data.trainingen.length; i++) {
							impressions[i] = [];
							impressions[i].id = data.trainingenids[i];
							impressions[i].name = data.trainingen[i];
							impressions[i].price = data.trainingenprices[i];
						}
						console.log(impressions);
						dataLayer.push({
							'ecommerce': {'impressions': impressions}});
						dataLayer.push({'event': 'StapTestSent'});
						window.location.href = url;
					
					}else{
						if(data == ""){
							$('#noresult').show();
							$('#result').hide();
							$('#result-related').hide();
							$('#nieuwsbrief-aanmelden').hide();
							$('html, body').animate({
								scrollTop: $("#noresult").offset().top
							}, 2000);
						}else{
							$('#result .step-module__result').html(jQuery(data).filter('#trainingen-result').html());
							$('#result-related .step-module__result').html(jQuery(data).filter('#trainingen-related').html());
							$('#noresult').hide();
							$('#result').show();
							$('#result-related').show();
							$('#nieuwsbrief-aanmelden').show();
							var trainingArr = [];
							$('input.chosen-training').each(function(){
								trainingArr.push($(this).val());
							});
							var trainingStr = trainingArr.join("|");
							var trainingurlArr = [];
							$('input.chosen-trainingurl').each(function(){
								trainingurlArr.push($(this).val());
							});
							var trainingurlStr = trainingurlArr.join("|");
							$('input.trainingen-names').val(trainingStr);
							$('input.trainingen-urls').val(trainingurlStr);
							$('html, body').animate({
								scrollTop: $("#result").offset().top
							}, 2000);
					
						}
					}
			  })
			  .fail(function(xhr, status, error) {
				alert( 'error' );
				console.log("Error:", xhr.responseText);
			  });
        });
        
    $('.step-module__section__choices')
        .on('click', "> div", function (e) {
			$(this).find('input').trigger( "change" );
        });


    $('.step-module__section__choices')
        .on('change', "> div input", function (e) {
        	var limit = 0;
        	if($(this).parent().parent().parent().find('input[name="choice-limit"]').length){
        		limit = $(this).parent().parent().parent().find('input[name="choice-limit"]').val();
        	}
        	var checked = $(this).parent().parent().find('div > input:checked').length;
        	var type = $(this).prop( "type");
        	
        	
        	if(checked < limit || limit == 0){
				if(!$(this).prop( "checked")){
					if(type == "radio"){
						$(this).parent().parent().find('div').removeClass('checked');
					}
					$(this).parent().addClass('checked');
				}else{
					$(this).parent().removeClass('checked');
			
				}
			}else{
				if($(this).prop( "checked")){
					$(this).parent().removeClass('checked');
				}
			}
			if(checked < limit || limit == 0 || $(this).prop("checked") == true){
				if($(this).prop( "checked")){
					$(this).prop( "checked", false);
					$(this).parent().removeClass('checked');
				}else{
					$(this).prop( "checked", true);
					$(this).parent().addClass('checked');
			
				}
			}
        	
        	if(limit > 0){
				//Set all others on disable
				checked = $(this).parent().parent().find('div > input:checked').length;
				if(checked == limit){
					$(this).parent().parent().find('div > input:not(:checked)').parent().addClass('disabled');
				}else{
					$(this).parent().parent().find('div').removeClass('disabled');
				}
			}
			
					//$(this).find('input').prop( "checked", false );
        });
});