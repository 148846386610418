$(function () {
if($('.review-slider').length){
   $('.review-slider').slick({
	  centerMode: true,
	  centerPadding: '0px',
	  slidesToShow: 1,
	  autoplay: true,
	  autoplaySpeed: 2000,
	  dots: true,
	  arrows: false,
	  
	  responsive: [
	  	{
		  breakpoint: 1024,
		  settings: {
			arrows: false,
			centerMode: true,
			dots: true,
			slidesToShow: 1
		  }
		},
		{
		  breakpoint: 768,
		  settings: {
			arrows: false,
			centerMode: true,
			dots: true,
			slidesToShow: 1
		  }
		},
		{
		  breakpoint: 480,
		  settings: {
			arrows: false,
			centerMode: true,
			dots: true,
			slidesToShow: 1
		  }
		}
	  ]
	});
}
});