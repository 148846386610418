$(function () {
    $('.trainers__filter').on('keyup', function () {
        var query = $(this).val().trim().toLowerCase();
        $(".trainer__trainersdiv__div").show().filter(function () {
			return $(this).find('.trainer__trainersdiv__div__textdiv__text__title').text().toLowerCase().indexOf(query) < 0;
		}).hide();
    });
});

$('#trainers_filter_category').on('change', function () {
    var selector = $(this).val();
    if(selector == 0){
        $('.trainer__trainersdiv__div').each(function(i, obj) {
            $(this).show();
        });
    }else{
        $('.trainer__trainersdiv__div').each(function(i, obj) {
            var tester = selector.toString();
            var trainercats = $(this).data("cat");
            var tester2 = trainercats.toString();
            if(tester2.indexOf(tester) > -1){
                $(this).show();
            }else{
                $(this).hide();
            }
        });
    }
});