document.addEventListener('DOMContentLoaded', function() {
    // Select the element you're interested in
    const targetElement = document.querySelector('#profileComplete');

    // Check if the element is present
    if (targetElement) {
        // Push an event to the data layer
        window.dataLayer.push({
            event: 'ProfileCompleted'
        });
    }

    // Select the element you want to add the click event listener to
    const startCareerTestButton = document.querySelector('#startCareerTest');

    // Check if the element is present
    if (startCareerTestButton) {
        // Add click event listener
        startCareerTestButton.addEventListener('click', function() {
            // Push an event to the data layer when the button is clicked
            window.dataLayer.push({
                event: 'StartCareerTest'
                // You can pass additional data here if needed
            });
        });
    }
});