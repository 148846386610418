function getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }    
    return age;
}

$(function () {
    $('input[name="birthdate"]')
        .on('focusout', function (e) {
            var enteredValue = $(this).val();
			var enteredAge = getAge(enteredValue);
			if( enteredAge < 18 ) {
				alert("Helaas mag je alleen van het STAP-budget gebruik maken als je minimaal 18 jaar oud bent.");
				//$(this).focus();
				return false;
			}
			
        });
});