$(function () {
    $('.form-checkout__stap-budget-callout__checkbox input')
        .on('change', function (e) {
        	if($('.form-checkout__stap-budget-callout__checkbox input').is(':checked')){
        		$.post('/cart/activatestap', { stap: true})
				.done(function( data, element ) {
					//alert( "Data Loaded: add " + data.status );
					if(data.status == "OK"){
						//alert('yaay1');
						location.reload();
						//$('.stap-hide').hide();
						//$('.cart-row__total-active-discount, .cart-row__totals-korting').hide();
					}
				  });
        	}else{
        		$.post('/cart/activatestap', { stap: false})
				.done(function( data, element ) {
					//alert( "Data Loaded: add " + data.status );
					if(data.status == "OK"){
						//alert('yaay2');
						location.reload();
						//$('.stap-hide').show();
						//$('.stap-hide.row').css('display','flex');
						//$('.cart-row__total-active-discount, .cart-row__totals-korting').show();
					}
				  });
        	}
        });
});