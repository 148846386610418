$(function () {
	var $optionselector = '.tab-link.type';
	var $trainingselector = '.column.training';
	var $opleidingselector = '.column.opleiding';
	var $lastminuteselector = '.column.lastminute';
	var $allesselector = '.trainingen-list__item';
	var $allesgridselector = '.grid-item';
    var $option = $($optionselector);
    var $training = $($trainingselector);
    var $opleiding = $($opleidingselector);
    var $lastminute = $($lastminuteselector);
    var $alles = $($allesselector);
    var $allesgrid = $($allesgridselector);

    if($option.length > 0 ) {
        //$header.addClass('closed');
        //$section.addClass('closed');

        $(document).on('click', $optionselector, function (e) {
            if( $(this).hasClass('opleidingen') ) {
                $($opleidingselector).show();
                $($trainingselector).hide();
                $option.removeClass('current');
                $(this).addClass('current');
            }
            else if( $(this).hasClass('cursussen') ) {
                $($opleidingselector).hide();
                $($trainingselector).show();
                $option.removeClass('current');
                $(this).addClass('current');
            }
            else if( $(this).hasClass('lastminutes') ) {
                $($opleidingselector).hide();
                $($trainingselector).hide();
                $($lastminuteselector).show();
                $option.removeClass('current');
                $(this).addClass('current');
            }
            else{
                $($trainingselector).show();
            	$($allesselector).show();
            	$($allesgridselector).show();
                $option.removeClass('current');
                $(this).addClass('current');
            }
            var listWithoutDisplayNone = $('div.grid-item').filter(function(){
				  if($(this).css('display') != 'none')
					   return $(this);
			});
            $('div.grid-item.keuzehulp').detach().insertAfter(listWithoutDisplayNone[4]);
        });
    }
	
	var $option2selector = '.tab-link.level';
	var $beginnerselector = '.column.beginner';
	var $advancedselector = '.column.advanced';
	var $expertselector = '.column.expert';
	var $alles2selector = '.trainingen-list__item';
	var $allesgrid2selector = '.grid-item';
    var $option2 = $($option2selector);
    var $training = $($trainingselector);
    var $opleiding = $($opleidingselector);
    var $lastminute = $($lastminuteselector);
    var $alles2 = $($alles2selector);
    var $allesgrid2 = $($allesgrid2selector);

    if($option2.length > 0 ) {
        //$header.addClass('closed');
        //$section.addClass('closed');

        $(document).on('click', $option2selector, function (e) {
            if( $(this).hasClass('beginner') ) {
                $($beginnerselector).show();
                $($advancedselector).hide();
                $($expertselector).hide();
                $option2.removeClass('current');
                $(this).addClass('current');
            }
            else if( $(this).hasClass('advanced') ) {
                $($beginnerselector).hide();
                $($advancedselector).show();
                $($expertselector).hide();
                $option2.removeClass('current');
                $(this).addClass('current');
            }
            else if( $(this).hasClass('expert') ) {
                $($beginnerselector).hide();
                $($advancedselector).hide();
                $($expertselector).show();
                $option2.removeClass('current');
                $(this).addClass('current');
            }
            else{
            	$($alles2selector).show();
            	$($allesgrid2selector).show();
                $option2.removeClass('current');
                $(this).addClass('current');
            }
            $( "div.grid-item.keuzehulp" ).insertAfter( "div.grid-item:nth-child(6)" ); 
        });
    }
    
	var $option3selector = '.tab-link.view';
	var $gridviewselector = '.grid-view';
	var $listviewselector = '.list-view';
    var $option3 = $($option3selector);
    var $gridview = $($gridviewselector);
    var $listview = $($listviewselector);

    if($option3.length > 0 ) {
        //$header.addClass('closed');
        //$section.addClass('closed');

        $(document).on('click', $option3selector, function (e) {
            if( $(this).hasClass('grid') ) {
                $($gridviewselector).show();
                $($listviewselector).hide();
                $option3.removeClass('current');
                $(this).addClass('current');
            }
            else{
                $($gridviewselector).hide();
                $($listviewselector).show();
                $option3.removeClass('current');
                $(this).addClass('current');
            }
        });
    }
    
    $('.categorie__overview__filter__option').click(function(){
		var filter = $(this).attr('data-filter');
		$('.categorie__overview__filter__option').removeClass('selected');
		$(this).addClass('selected');
		$('.categorie__overview__filter__grid .grid-item, .categorie__overview__filter__list .training-list-item, .categorie__overview__filter__barebonelist .training-list-item').hide();
		if(filter == "alles"){
			$('.categorie__overview__filter__grid .grid-item, .categorie__overview__filter__list .training-list-item, .categorie__overview__filter__barebonelist .training-list-item').show();
		}else{
			$('.categorie__overview__filter__grid .grid-item.'+filter+', .categorie__overview__filter__list .training-list-item.'+filter+', .categorie__overview__filter__barebonelist .training-list-item.'+filter).show();
		}
		//$("#tab-1").hide();
		//$("#tab-filter").show();
  		//var slideIndex = $('.design-training-slider .'+filter);
 		//var saveStuff = $('.design-training-slider .'+filter).html();
 		
		//Save HTML of elements
	});

	// New duplicated function for keuzehulp with new classe names
    $('.keuzehulp__mainbar__content__menu__option').click(function(){
		var filter = $(this).attr('data-filter');
		var korting = $('input[name="korting"]').val();
		var activateDiscount = false;
		if(!$(this).hasClass('selected')){
			$('.keuzehulp__mainbar__content__menu__option').removeClass('selected');
			$(this).addClass('selected');
			$('.categorie__overview__filter__grid .grid-item, .categorie__overview__filter__list .training-list-item, .categorie__overview__filter__barebonelist .training-list-item').hide();
			$('input[name="korting"]').val('0');
			if(filter == "alles"){
				$('.categorie__overview__filter__grid .grid-item, .categorie__overview__filter__list .training-list-item, .categorie__overview__filter__barebonelist .training-list-item').show();
			}else{
				$('.categorie__overview__filter__grid .grid-item.'+filter+', .categorie__overview__filter__list .training-list-item.'+filter+', .categorie__overview__filter__barebonelist .training-list-item.'+filter).show();
			}
			if(filter == "korting"){
				$('input[name="korting"]').val('1');
				$('.vorm').val('0');
			}else if(filter == "training"){
				$('.vorm').val('training');
			}else if(filter == "opleiding"){
				$('.vorm').val('opleiding');
			}else{
				$('.vorm').val('0');
			}
			locked = true;
			$('#currentPage').val("1");
			//$('.form-keuzehulp input').trigger('change');

		sendform();
		}
	
	});
	
	var filterType = '';
	var filterCategory = '';
	
	$('.tab-title.filter select').change(function(){
		if($(this).val() == "alles"){
			//$('.trainingen-grid__item').show();
			$('.design-training-slider-test').slick('slickUnfilter');
			updateSliderCounterTryout('.design-training-slider-test');
			$('.column.grid-item').show();
			filterType = '';
		}else{
			//$('.trainingen-grid__item').hide();
			//$('.trainingen-grid__item.'+$(this).val()).show();
			$('.design-training-slider-test').slick('slickUnfilter');
			$('.design-training-slider-test').slick('slickFilter','.'+$(this).val()+filterCategory);
			updateSliderCounterTryout('.design-training-slider-test');
			$('.column.grid-item').hide();
			$('.column.grid-item.'+$(this).val()+filterCategory).show();
			filterType = '.'+$(this).val();
		}
	
	});
	
	$('.tab-title.categories .tab-link').click(function(){
		
			$('.tab-title.categories .tab-link').removeClass('current');
			$(this).addClass('current');
		if($(this).attr('data-filter') == "alles"){
			$('.design-training-slider-test').slick('slickUnfilter');
			$('.column.grid-item').show();
			filterCategory = '';
			$('.design-training-slider-test').slick('slickGoTo', 0);
		}else{
			$('.design-training-slider-test').addClass("filteractive");
			$('.design-training-slider-test').slick('slickUnfilter');
			$('.design-training-slider-test').slick('slickFilter','.'+$(this).attr('data-filter')+filterType);
			$('.column.grid-item').hide();
			$('.column.grid-item.'+$(this).attr('data-filter')+filterType).show();
			filterCategory = '.'+$(this).attr('data-filter');
			let currentSlide = $('.design-training-slider-test').slick('slickCurrentSlide') + 1;
			let totalSlide = $('.design-training-slider-test').slick("getSlick").slideCount;
			$('.design-training-slider-test .slider__counter').text(currentSlide+'/'+totalSlide);
			$('.design-training-slider-test').slick('slickGoTo', 0);
		}
	
	});
	
	$('.mobile-filter__expand .tab-link, .sub-category__filter .tab-link.category').click(function(){
		$('.sub-category__filter .tab-link.category').removeClass('current');
		$(this).addClass('current');
		if($(this).attr('data-filter') == "alles"){
			$('.column.grid-item').show();
			filterType = '';
		}else{
			$('.column.grid-item').hide();
			$('.column.grid-item.'+$(this).attr('data-filter')+filterCategory).show();
			filterType = '.'+$(this).attr('data-filter');
		}
	
	});
	
	$('.hide-for-medium .loadmore button').click(function(){
		//Collect filters
		var categoryfilter = $('.tab-title.categories .tab-link').attr('data-filter');
		var typefilter = $('.tab-title.filter select').val();
		if(categoryfilter == "alles"){
			categoryfilter = '';
		}else{
			categoryfilter = '.'+categoryfilter;
		}
		if(typefilter == "alles"){
			typefilter = '';
		}else{
			typefilter = '.'+typefilter;
		}
		//Calculate length of items
		var length = $('.column.grid-item'+categoryfilter+typefilter).length;
		//Get number of shown items
		var visiblelength = $('.column.grid-item'+categoryfilter+typefilter+':visible').length;
		//Calculate difference
		var difference = length - visiblelength;
		//alert(difference);
		if(difference <= 5){
			$('.column.grid-item'+categoryfilter+typefilter).show();
			$('.loadmore').hide();
		}else{
			$('.column.grid-item'+categoryfilter+typefilter).slice(visiblelength, visiblelength+5).show();
		}
	
	});
	$('.mobile-filter__expand__close-button, .mobile-filter__button').click(function(){
		$('.mobile-filter__expand').toggle();
	});
});


//new keuzehulp filter voor snapslider.
$(' .tab-link').click(function(){
		if($(this).attr('data-filter') == "alles"){
			jQuery(".keuzehulp_filter-contentblock").detach().appendTo('#keuzehulp_filter-filterrow')
		}else{
			jQuery(".keuzehulp_filter-contentblock").detach().appendTo('#keuzehulp_filter-leegcontainer')
			$('.keuzehulp_filter-category'+$(this).attr('data-filter')).detach().appendTo('#keuzehulp_filter-filterrow')
			$('.keuzehulp_filter-'+$(this).attr('data-filter')).detach().appendTo('#keuzehulp_filter-filterrow')
		}

});