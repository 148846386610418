$(function () {
   $('.logo-slider').slick({
	  centerMode: true,
	  centerPadding: '0px',
	  slidesToShow: 8,
	  autoplay: true,
	  autoplaySpeed: 2000,
	  dots: false,
	  
	  responsive: [
	  	{
		  breakpoint: 1224,
		  settings: {
			arrows: false,
			centerMode: true,
			dots: false,
			slidesToShow: 5
		  }
		},
		{
		  breakpoint: 768,
		  settings: {
			arrows: false,
			centerMode: true,
			dots: false,
			slidesToShow: 3
		  }
		},
		{
		  breakpoint: 480,
		  settings: {
			arrows: false,
			centerMode: true,
			dots: false,
			slidesToShow: 2
		  }
		}
	  ]
	});
});