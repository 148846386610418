$(function () {
    $('#inschrijven-nieuwsbrief')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();

            $.post(form.attr("action"), form.serialize(), function (response) {
                $('#nieuwsbrief-done').foundation('open');
            	window.dataLayer.push({'email': response.email});
            	window.dataLayer.push({'userId': response.userid});
                window.dataLayer.push({'firstname': response.firstname});
                window.dataLayer.push({'lastname': response.lastname});
                window.dataLayer.push({'phone': response.phone});
            	dataLayer.push({'event': 'NieuwsbriefFormSubmit'});
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        });
    
    $('#stap-uitslag')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();

            $.post(form.attr("action"), form.serialize(), function (response) {
                $('#stap-uitslag-done').foundation('open');
            	dataLayer.push({'event': 'STAPUitslagSubmit'});
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        });
    $('#nieuwsbrief-footer')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();
            $.post(form.attr("action"), form.serialize(), function (response) {
                $('#nieuwsbrief-footer-done').foundation('open');
                //console.log(response.userid);
            	window.dataLayer.push({'email': response.email});
            	window.dataLayer.push({'userId': response.userid});
                window.dataLayer.push({'firstname': response.firstname});
                window.dataLayer.push({'lastname': response.lastname});
                window.dataLayer.push({'phone': response.phone});
                //console.log(response.userid);
            	window.dataLayer.push({'event': 'NieuwsbriefFormSubmit'});
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        });
});