$(function () {
	var $readmoreselector = '.categorie__sidebar__readmore';
	var $sectionselector = '.categorie__sidebar__content';
    var $readmore = $($readmoreselector);
    var $section = $($sectionselector);

    if($readmore.length > 0 ) {
        $section.hide();
        $section.addClass('closed');

        $readmore.on('click', function (e) {
            if( $section.hasClass('closed') ) {
            	$(this).html('- Lees Minder');
                $section.show();
                $section.removeClass('closed');
            }
            else{
            	$(this).html('+ Lees Meer');
            	$section.hide();
                $section.addClass('closed');
            }
        });
    }
});