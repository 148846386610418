$(function () {
if($('.speaker-slider').length){
   $('.speaker-slider').slick({
	  centerMode: true,
	  centerPadding: '0px',
	  slidesToShow: 5,
	  
	  responsive: [
	  	{
		  breakpoint: 1024,
		  settings: {
			arrows: true,
			centerMode: true,
			dots: true,
			slidesToShow: 3
		  }
		},
		{
		  breakpoint: 768,
		  settings: {
			arrows: true,
			centerMode: true,
			dots: true,
			slidesToShow: 2
		  }
		},
		{
		  breakpoint: 480,
		  settings: {
			arrows: true,
			centerMode: true,
			dots: true,
			slidesToShow: 1
		  }
		}
	  ]
	});
}
if($('.speaker-slider-google-factory').length){

	$('.speaker-slider-google-factory').slick({
	  centerMode: true,
	  centerPadding: '0px',
	  slidesToShow: 4,
	  
	  responsive: [
	  	{
		  breakpoint: 1024,
		  settings: {
			arrows: true,
			centerMode: true,
			dots: true,
			slidesToShow: 3
		  }
		},
		{
		  breakpoint: 768,
		  settings: {
			arrows: true,
			centerMode: true,
			dots: true,
			slidesToShow: 2
		  }
		},
		{
		  breakpoint: 480,
		  settings: {
			arrows: true,
			centerMode: true,
			dots: true,
			slidesToShow: 1
		  }
		}
	  ]
	});
}
if($('.speaker-slider-data-factory').length){
	$('.speaker-slider-data-factory').slick({
	  centerMode: true,
	  centerPadding: '0px',
	  slidesToShow: 3,
	  
	  responsive: [
	  	{
		  breakpoint: 1024,
		  settings: {
			arrows: true,
			centerMode: true,
			dots: true,
			slidesToShow: 3
		  }
		},
		{
		  breakpoint: 768,
		  settings: {
			arrows: true,
			centerMode: true,
			dots: true,
			slidesToShow: 2
		  }
		},
		{
		  breakpoint: 480,
		  settings: {
			arrows: true,
			centerMode: true,
			dots: true,
			slidesToShow: 1
		  }
		}
	  ]
	});
}
if($('.image-slider').length){
	$('.image-slider').slick({
	  centerMode: true,
	  centerPadding: '300px',
	  slidesToShow: 1,
	  centerMode: true,
	  dots: true,
	  arrows: false,
	  
	  responsive: [
	  	{
		  breakpoint: 1024,
		  settings: {
			arrows: false,
			centerMode: true,
	  		centerPadding: '100px',
			dots: true,
			slidesToShow: 1
		  }
		},
		{
		  breakpoint: 768,
		  settings: {
			arrows: false,
			centerMode: true,
	  		centerPadding: '60px',
			dots: true,
			slidesToShow: 1
		  }
		},
		{
		  breakpoint: 480,
		  settings: {
			arrows: false,
			centerMode: true,
			dots: true,
	  		centerPadding: '0px',
			slidesToShow: 1
		  }
		}
	  ]
	});
}
});