$(function () {
    //$('.slickslider').slick({"slidesToShow": 4, "slidesToScroll": 1, infinite: true});
    $('.training_image_slider').slick({
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: false,
        arrows: true,
        centerPadding: '80px',
        prevArrow: '<button class="slide-arrow prev-arrow"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"></button>',
      
        responsive: [
          {
          breakpoint: 1024,
          settings: {
            arrows: false,
            centerMode: true,
            dots: true,
            slidesToShow: 1
          }
          },
          {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            dots: true,
            slidesToShow: 1
          }
          },
          {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            dots: true,
            slidesToShow: 1
          }
          }
        ]
        });

        var $imageslider = $('.imageslider');

        if ($imageslider.length) {
            var currentSlide;
            var slidesCount;
            var sliderCounter = document.createElement('div');
            sliderCounter.classList.add('slider__counter');
            
            var updateSliderCounter = function(slick, currentIndex) {
              currentSlide = slick.slickCurrentSlide() + 1;
              slidesCount = slick.slideCount;
              $(sliderCounter).text(currentSlide + '/' +slidesCount)
            };
          
            $imageslider.on('init', function(event, slick) {
              $imageslider.append(sliderCounter);
              updateSliderCounter(slick);
            });
          
            $imageslider.on('afterChange', function(event, slick, currentSlide) {
              updateSliderCounter(slick, currentSlide);
            });
          
            $imageslider.slick();
          }


});