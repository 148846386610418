$(function () {
    $('.login-modal__switch__option').click(function(e){
		var current = $(this).parent().attr('class').replace(' row', '');
		var show = $(this).attr('data-show');
		$('.row.'+current).hide();
		$('.login-modal__content').hide();
		$('.row.'+show+',.login-modal__content.'+show).show();
	});
    $('.login-modal__content .switch-link').click(function(e){
    	e.preventDefault();
		var show = $(this).attr('data-show');
		$('.login-modal__content').hide();
		$('.login-modal__content.'+show).show();
	});
});