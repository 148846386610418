$(function () {
    $(document).on('click', 'a[href^="#"]', function (event) {
		event.preventDefault();
		if($.attr(this, 'data-innerselector') !== undefined && window.innerWidth >= 1024){
			console.log('test'); 
			var id = $.attr(this, 'href').substring(1);
			var myElement = document.getElementById(id);
			var topPos = myElement.offsetTop;
			var idSelector = $.attr(this, 'data-innerselector');
			console.log(topPos);
			console.log(document.getElementById($.attr(this, 'data-innerselector')).scrollTop);
			//document.getElementById($.attr(this, 'data-innerselector')).scrollTop = topPos;
			$('#'+idSelector).animate({
				scrollTop: topPos
			}, 500, 'swing');

		}else{
			var position = $($.attr(this, 'href')).offset().top-100;
			$('html, body').animate({
				scrollTop: position
			}, 500, 'swing', function(){
			const currentScroll = window.pageYOffset;
			const body = document.body
			const scrollUp = "scroll-up";
			const scrollDown = "scroll-down";
			const scrollSubmenu = "scroll-submenu";
			const scrollClose = "scroll-header";
			if (currentScroll <= 400) {
				body.classList.add(scrollClose);
			}else{
				body.classList.remove(scrollClose);
			}
				window.scrollTo(window.scrollX, window.scrollY + 1);
			});
		
			//window.dispatchEvent(new CustomEvent('scroll'));
			//window.scrollTo(window.scrollX, window.scrollY + 1);
		}
		
	});
});