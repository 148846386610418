'use strict';
//$('.training-nav__subnav').hide();
/*$(function () {
    var delay = 300;
    var open = false;
    var loaded = false;
    
            
    var init = function () {
        loaded = true;

        $('.topnav-desktop__menu-item--trainingen').on('click', function (e) {
            e.preventDefault();
        });

        // Load training menu
        $.get($('#training-nav-container').data('url'), false, function ($data) {
            $('#training-nav-container').html($data);
			
            $('.topnav-desktop__menu-item--trainingen').on('click', function (e) {
                e.preventDefault();

                if (!open) {
                    $('.training-nav').fadeIn(250);
                    $(this).addClass('topnav__menu-item--open');
                    open = true;
                }
                else {
                    $('.training-nav').fadeOut(250);
                    $(this).removeClass('topnav__menu-item--open');
                    open = false;
                }

                return false;
            });

            $(document).click(function (e) {
                if (!$(e.target).closest('.training-nav__menu-container').length) {
                    if (open) {
                        $('.training-nav').fadeOut(250);
                        $(this).removeClass('topnav-desktop__menu-item--open');
                        open = false;
                    }
                }
            });

            var $categoryLink = $('.training-nav__category-link');
            var $categoryLinksecond = $('.digitalartschool .training-nav__category-link');

            // Verberg alle subnavigatie zodat we ze per categorie kunnen tonen on mouse over
            //$('.training-nav__subnav').hide();
            //$('.marketingbusinessschool .training-nav__subnav:first').show();
            //$('.digitalartschool .training-nav__subnav:first').show();
            $categoryLink.first().addClass('active');
            $categoryLinksecond.first().addClass('active');

            var showTimeout = false;

            $categoryLink.on('mouseover', function () {
                var cat = $(this).data('category');
                var $el = $(this);

                showTimeout = setTimeout(function () {
                    $categoryLink.removeClass('active');
                    $el.addClass('active');
                    $('.training-nav__subnav').hide();
                    $('#training-nav-category-' + cat).show();
                }, delay);
            });

            $categoryLink.on('click', function () {
                clearTimeout(showTimeout);

                var cat = $(this).data('category');
                var $el = $(this);

                $categoryLink.removeClass('active');
                $el.addClass('active');
                $('.training-nav__subnav').hide();
                $('#training-nav-category-' + cat).show();
            });

            $categoryLink.on('mouseleave', function () {
                clearTimeout(showTimeout);
            });

        }, 'html');
    };

    var mq = Foundation.MediaQuery.current;

    if (mq != 'small' && mq != 'medium') {
        init();
    }

    $(window).on('changed.zf.mediaquery', function (event, mq) {
        if (!loaded && mq != 'small' && mq != 'medium') {
            init();
        }
    });
});*/

