/* contact page slider */
$( document ).ready(function() {
	$(function () {
		if($('.design-training-slider-contact').length){
			   $('.design-training-slider-contact').slick({
				  slidesToShow: 1,
				  autoplay: true,
				  autoplaySpeed: 2000,
				  dots: false,
				  arrows: true,
				  centerMode: true,  
				  centerPadding: '80px',
		  
				  responsive: [
					{
					  breakpoint: 1024,
					  settings: {
						arrows: false,
						centerMode: true,
						dots: true,
						slidesToShow: 3
					  }
					},
					{
					  breakpoint: 768,
					  settings: {
						arrows: false,
						centerMode: true,
						dots: true,
						slidesToShow: 2
					  }
					},
					{
					  breakpoint: 480,
					  settings: {
						arrows: false,
						centerMode: true,
						dots: true,
						slidesToShow: 1
					  }
					}
				  ]
				});
		}
	});
});