'use strict';

$(function () {
    var initPlaceholder = function (el) {

        var $input = $(el).find('.search-form__input:first')
        var $clear = $(el).find('.search-form__clear')
        var $placeholder = $(el).find('.search-form__placeholder:first');

        if ($placeholder.length > 0) {
            $placeholder.on('click', function () {
                $input.focus();

            });

            $input.on('focus', function () {
                $placeholder.hide();
                $(el).addClass('open');
            });

            $input.on('blur', function () {
                if ($input.val().trim().length == 0) $placeholder.show();
                $(el).removeClass('open');
            });
        }
        $input.on('keyup', function(){
            if($(this).val() != ""){
                $('.search-form__clear').show();
                $('.training__actiebalk').hide();
            }else{
                $('.search-form__clear').hide();
                if(!window.location.includes('sqr:')){
                    $('.training__actiebalk').show();
                }
            }
        });
        $clear.on('click', function(){
            $('.search-form__clear').hide();
            $('.sqr-closeButton').trigger('click');
            $('.training__actiebalk').show();
        });
        $input.on('focus', function () {
        	$('body').addClass('search-open');
			$(el).addClass('open');
		});
		$input.on('blur', function () {
            //if ($input.val().trim().length == 0) $placeholder.show();
            $(el).removeClass('open');
            $('body').removeClass('search-open');
        });
    }

    $('.search-form').each(function (i, el) {
        initPlaceholder(el);
    });
    $('.mobilesearchtoggle').on('click', function(){
    	//alert('test');
    	$('.header__search-mobile').toggle();
    });
});