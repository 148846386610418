$(function () {
	/*var $optionselector = '.categorie__overview__filter__option';
	var $trainingselector = '.training';
	var $opleidingselector = '.opleiding';
	var $lastminuteselector = '.lastminute';
	var $allesselector = '.trainingen-list__item';
	var $allesgridselector = '.training-list-item';
    var $option = $($optionselector);
    var $training = $($trainingselector);
    var $opleiding = $($opleidingselector);
    var $lastminute = $($lastminuteselector);
    var $alles = $($allesselector);
    var $allesgrid = $($allesgridselector);

    if($option.length > 0 ) { 
        //$header.addClass('closed');
        //$section.addClass('closed');

        $(document).on('click', '.categorie__overview__filter__option', function (e) {
            if( $(this).hasClass('opleidingen') ) {
                $($opleidingselector).show();
                $($trainingselector).hide();
                $option.removeClass('selected');
                $(this).addClass('selected');
            }
            else if( $(this).hasClass('cursussen') ) {
                $($opleidingselector).hide();
                $($trainingselector).show();
                $option.removeClass('selected');
                $(this).addClass('selected');
            }
            else if( $(this).hasClass('lastminutes') ) {
                $($opleidingselector).hide();
                $($trainingselector).hide();
                $($lastminuteselector).show();
                $option.removeClass('selected');
                $(this).addClass('selected');
            }
            else{
            	$($allesselector).show();
            	$($allesgridselector).show();
                $option.removeClass('selected');
                $(this).addClass('selected');
            }
        });
    }*/
});