$(function () {
  $(".account__profile__bar__mobile-item.search").click(function (e) {
    $(".account__profile__bar__mobile-item").toggle();
    $("#SearchBar").fadeIn();
  });
  $(".keuzehulp__profile__bar__mobile-item.search").click(function (e) {
    $(".keuzehulp__profile__bar__mobile-item").toggle();
    $("#SearchBar").fadeIn();
  });
  $("#SearchBar .close-button").click(function (e) {
    $("#SearchBar").fadeOut(400, function () {
      $(".account__profile__bar__mobile-item").toggle();
      $(".keuzehulp__profile__bar__mobile-item").toggle();
    });
  });
});
