/*bioEp.init({	
	height: 700,
	width: 600,
	cookieExp : 30
});*/

$(function () {
    $('#inschrijven-popup')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();

            $.post(form.attr("action"), form.serialize(), function (response) {
                $('#nieuwsbrief-done').foundation('open');
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        })
});