/*$(function () {
    //Optie nemen tracken
    $( ".inschrijven-optie__optie-link" ).click(function() {
    	ga('send', 'event', 'Button', 'click', 'Optie Nemen');
  	});
  	//Inschrijven tracken
  	$( ".inschrijven-optie__inschrijven-button" ).click(function() {
    	ga('send', 'event', 'Button', 'click', 'Inschrijven');
  	});
  	  
});*/
$(function () {
  	
	$('a.cart__optie, a.cart__next').on('click', function (e) {
    	
    	//Loop through cart
    	var cartitems = [];
    	$('.cart-item').each(function(index){
    		var item_id = $(this).find('.cart-item-sku').val();
    		var item_name = $(this).find('.cart-row__name').text();
    		var actie = false;
    		if($(this).find('.cart-item-discount').length > 0){
    			var item_coupon = $(this).find('.cart-item-coupon').val();
    			var item_discount = $(this).find('.cart-item-discount').val();
    			actie = true;
    		}
    		var item_quantity = $(this).find('.cart-row__aantal').val();
    		var item_price = $(this).find('.cart-item-subtotal').val();
    		var item_type = $(this).find('.cart-item-type').val();
    		if(actie){
    			var item = {"item_id" : item_id, "item_name" : item_name, "coupon" : item_coupon, "discount" : item_discount, "affiliation" : "Competence Factory", "currency" : "EUR", "item_variant" : item_type, "index" : index, "price" : item_price, "quantity" : item_quantity};
    		}else{
    			var item = {"item_id" : item_id, "item_name" : item_name, "affiliation" : "Competence Factory", "currency" : "EUR", "item_variant" : item_type, "index" : index, "price" : item_price, "quantity" : item_quantity};
    		}
    		
    		
    		cartitems.push(item);
    	});
    	//console.log(cartitems);
		dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		dataLayer.push({ 
			event: "begin_checkout",
		  	ecommerce: {
				items: cartitems
		  	}
    	});
    });
    $('.form-checkout__payment-method button[type="submit"]').on('click', function (e) {
    	
    	//Loop through cart
    	var cartitems = [];
    	$('.cart-item').each(function(index){
    		var item_id = $(this).find('.cart-item-sku').val();
    		var item_name = $(this).find('.cart-row__name').text();
    		var actie = false;
    		if($(this).find('.cart-item-discount').length > 0){
    			var item_coupon = $(this).find('.cart-item-coupon').val();
    			var item_discount = $(this).find('.cart-item-discount').val();
    			actie = true;
    		}
    		var item_quantity = $(this).find('.cart-row__aantal').val();
    		var item_price = $(this).find('.cart-item-subtotal').val();
    		var item_type = $(this).find('.cart-item-type').val();
    		if(actie == true){
    			var item = {"item_id" : item_id, "item_name" : item_name, "coupon" : item_coupon, "discount" : item_discount, "affiliation" : "Competence Factory", "currency" : "EUR", "item_variant" : item_type, "index" : index, "price" : item_price, "quantity" : item_quantity};
    		}else{
    			var item = {"item_id" : item_id, "item_name" : item_name, "affiliation" : "Competence Factory", "currency" : "EUR", "item_variant" : item_type, "index" : index, "price" : item_price, "quantity" : item_quantity};
    		}
    		
    		
    		cartitems.push(item);
    	});
    	var paymentinfo = $('input[name="betaalmethode"]:checked').val();
    	var total = $('#totaal').val();
    	//console.log(cartitems);
		dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
		dataLayer.push({ 
			event: "add_payment_info",
		  	ecommerce: {
				currency: "EUR",
				value: total,
				payment_type: paymentinfo,
				items: cartitems
		  	}
    	});
    	//console.log(dataLayer);
    	//e.preventDefault();
    	
    });
});









