/**
 * keuzehulp.js
 */
$(function () {
    $('.step-button').on('click', function() {
        var step = $(this).attr('class').split(' ')[0];
        //$('#'+step+' input').foundation('validateInput', $('#'+step+' input'));
        //$('#maatwerk').foundation('validateInput', $('#ac'));
        var id = $(this).closest('div.step').attr('id');
        if(id == "step2"){
        
    		$('#maatwerk').foundation('validateInput', $('#'+id+' select'));
        }
        //alert($('#'+id+' .form-error').filter(":visible").length);
        if($('#'+id+' .form-error').filter(":visible").length < 1){
        
			$('div.step').hide();
			
			$('#'+step).show();
			if(step == "step2" || step == "step3"){
				$('.cart__steps__step').removeClass('checked');
				$('.cart__steps__step.'+step).addClass('checked');
			}
        }
        
    });
    
    $('#maatwerk input').on('focusout', function(){
    	$('#maatwerk').foundation('validateInput', $(this));
    });
    /*$('.signup-dtyc').on('click', function() {
    });*/
    
    $('#maatwerk')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();

            $.post(form.attr("action"), form.serialize(), function (response) {
                //$('#maatwerk-done').foundation('open');
            	$('#maatwerk').trigger("reset");
				$('.cart__steps__step').removeClass('checked');
				$('.cart__steps__step.yougotthis').addClass('checked');
				$('.evenementform').hide();
				$('.confirmation').show();
            	dataLayer.push({'event': 'MaatwerkFormSubmit'});
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        })

});