let interval;
const eventDay = new Date('11/22/2024 23:59');
//console.log(eventDay);
const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;

/*

const countDownFn = () => {
	// All logic goes in
	let now = new Date();
	let timeSpan = eventDay - now;
	if (timeSpan <= -now) {
		console.log("Unfortunately we have past the event day");
		clearInterval(interval);
	}else if (timeSpan <= 0) {
		console.log("Today is the event day");
		clearInterval(interval);
		return;
	}else {
		const days = Math.floor(timeSpan / day)
		const hours = Math.floor((timeSpan % day) / hour)
		const minutes = Math.floor((timeSpan % hour) / minute)
		const seconds = Math.floor((timeSpan % minute) / second)

		console.log(days + ":" + hours + ":" + minutes + ":" + seconds);
	}
}

interval = setInterval(countDownFn, second);*/

function flipTo(digit, n){
  var current = digit.attr('data-num');
  digit.attr('data-num', n);
  digit.find('.front').attr('data-content', current);
  digit.find('.back, .under').attr('data-content', n);
  digit.find('.flap').css('display', 'block');
  setTimeout(function(){
    digit.find('.base').text(n);
    digit.find('.flap').css('display', 'none');
  }, 350);
}

function jumpTo(digit, n){
  digit.attr('data-num', n);
  digit.find('.base').text(n);
}

function updateDays(n, flip){
 var dayelement = $('.counter_item__days');
  n = String(n);
 dayelement.text(n);
 if(flip){
 var daytitle = $('.counter_item__days-title');
   if(daytitle.length > 0){	 
	 if(n == 1){
	 	daytitle.text('dag');
	 }else{
	 	daytitle.text('dagen');
	 
	 }
   }
 }
}
function updateGroup(group, n, flip){
  var digit1 = $('.ten'+group);
  var digit2 = $('.'+group);
  n = String(n);
  if(flip){
  if(n.length == 1) n = '0'+n;
  var num1 = n.substr(0, 1);
  var num2 = n.substr(1, 1);
  if(digit1.attr('data-num') != num1){
    if(flip) flipTo(digit1, num1);
    else jumpTo(digit1, num1);
  }
  if(digit2.attr('data-num') != num2){
    if(flip) flipTo(digit2, num2);
    else jumpTo(digit2, num2);
  }
  }else{
	jumpTo(digit2, n);
  }
}

function setTime(flip){
  //var t = new Date('09/01/2022');
  let now = new Date();
	let timeSpan = eventDay - now;
	if (timeSpan <= -now) {
		//console.log("Unfortunately we have past the event day");
		clearInterval(interval);
	}else if (timeSpan <= 0) {
		//console.log("Today is the event day");
		clearInterval(interval);
		return;
	}else {
		const days = Math.floor(timeSpan / day) - 1
		const hours = Math.floor((timeSpan % day) / hour)
		const minutes = Math.floor((timeSpan % hour) / minute)
		const seconds = Math.floor((timeSpan % minute) / second)
		//if(days > 0){
			updateDays( days + 1, flip );
		//}
		updateGroup('hour', hours, flip);
  		updateGroup('min', minutes, flip);
  		updateGroup('sec', seconds, flip);
		//console.log(days + ":" + hours + ":" + minutes + ":" + seconds);
	}
  
}

document.addEventListener("DOMContentLoaded", function() {
	const counterElement = document.querySelector('.counter');
	
	// Check if the element exists
	if(counterElement) {
	  setTime(false);
	  
	  // Check if the element has the 'simple' class
	  if (counterElement.classList.contains('simple')) {
		// Set an interval to call setTime every second
		setInterval(function() {
			setTime(false);
		  }, 1000);
	  } else {
		// Set an interval to call setTime every second
		setInterval(function() {
			setTime(true);
		  }, 1000);
	  }
	}
  });