/**
 * checkout.js
 */
$(function () {
    var applyShoppingBasketRowUpdate = function () {

        if(window.innerWidth > 1024){
            var layoutselector = '.desktop_layout_checkout';
        }else{
            var layoutselector = '.mobile_layout_checkout';
        }
        $('body').on('change', '.cart-row__form', function () {
            $('#shopping-cart-body').addClass('disabled');
            //console.log($(this).serialize());
            $.post($(this).prop('action'), $(this).serialize(), function (data) {
                //console.log(data);
                $('#shopping-cart-body').removeClass('disabled');
                var receipt = $(data).find('.checkout__receipt').html();
                var body = $(data).find('#shopping-cart-body').html();
                $('.checkout__receipt').html(receipt);
                $(layoutselector+' #shopping-cart-body').html(body);
                //applyShoppingBasketRowUpdate();
                //location.reload();
            }, 'html');
        });

        $('.cart').on('click', '.cart-row__delete', function (e) {
            e.preventDefault();
            $('#shopping-cart-body').addClass('disabled');
            $.get($(this).prop('href'), function (data) {
                $('#shopping-cart-body').removeClass('disabled');
                $('.checkout__receipt').html(data);
                location.reload();
            }, 'html');

            return false;
        });

        /*$('.form-checkout__stap-budget-callout__checkbox input').on('change', function (e) {
            $('#shopping-cart-body').addClass('disabled');
            $.get($(this).prop('href'), function (data) {
                var content = data.find('#shopping-cart-body');
                $('#shopping-cart-body').html(content).removeClass('disabled');
                applyShoppingBasketRowUpdate();
            }, 'html');

            return false;
        });*/
        
        $('#sinterklaasactie').on('change', function() {
            $('#shopping-cart-body').addClass('disabled');
            $.post($(this).prop('action'), $(this).serialize(), function (data) {
                $('#shopping-cart-body').html(data).removeClass('disabled');
                applyShoppingBasketRowUpdate();
            }, 'html');
        });
        
    };

    applyShoppingBasketRowUpdate();

    $('.cart-dropdown__menu__section.items a').on('click', function(e){
        e.preventDefault();

        var cartRow = $(this).parent().parent().parent();
        $.get($(this).prop('href'), function (data) {
            if(data.status == "OK"){
                console.log('succes');
                cartRow.hide('slow', function(){ 
                    $(this).remove(); 
                    updateCartQty();
                });
                
            }
            
        });
    });
    
});

function updateCartQty(){
    var cartitems = $('.cart-dropdown__menu__section.items').length;
    console.log(cartitems);
    $('.header__cart-quantity').html(cartitems);
    if(cartitems == 0){    
        $('.header__cart-quantity').hide();
        if($('#link-shoppingcart').hasClass('header__cart-desktop--filled')){
            $('#link-shoppingcart').removeClass('header__cart-desktop--filled');
        }
    }

}