$(function () {
    $('#SearchBar input').on('keyup', function () {
        var query = $(this).val().trim().toLowerCase();
        $(".grid-view .grid-item").show().filter(function () {
			return $(this).find('p.training-grid-block__title').text().toLowerCase().indexOf(query) < 0;
		}).hide();
        $(".list-view .grid-item").show().filter(function () {
			return $(this).find('h3').text().toLowerCase().indexOf(query) < 0;
		}).hide();
    });
});