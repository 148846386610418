
$(function () {
	/*FAQ start*/

	$('.toggle').click(function(e) {
		e.preventDefault();
  
		let $this = $(this);
		if ($this.parent().hasClass('current')) {
			$this.parent().parent().find('> li').removeClass('current');
			$this.parent().parent().find('> div').removeClass('current');
			//console.log('it is currently selected');
			if($this.parent().hasClass('closed')){
				$this.next().slideToggle(500);
				$this.parent().removeClass('closed');
			}else{
				//console.log('it is not closed');
				$this.parent().parent().find('li .inner').removeClass('show');
				$this.parent().parent().find('div .inner').removeClass('show');
				$this.parent().parent().find('li .inner').slideUp(500);
				$this.parent().parent().find('div .inner').slideUp(500);
				$this.parent().removeClass('current');
				$this.parent().addClass('closed');
			}
		}else{
			//console.log('it is currently not selected');
			$this.parent().addClass('current');
			// alert($this.parent());
			if ($this.next().hasClass('show')) {
				$this.parent().parent().find('li:not(.current) .inner').removeClass('show');
				$this.parent().parent().find('div:not(.current) .inner').removeClass('show');
				//$this.next().removeClass('show');      
				// $this.parent().addClass('active');
				//$this.next().slideUp(500);
			} else {
				$this.parent().parent().find('li:not(.current) .inner').removeClass('show');
				$this.parent().parent().find('div:not(.current) .inner').removeClass('show');
				$this.next().addClass('show');
				// $this.parent().removeClass('active');
				$this.parent().parent().find('li .inner').slideUp(500);
				$this.parent().parent().find('div .inner').slideUp(500);
				$this.next().toggleClass('show');
				$this.next().slideToggle(500);
			}
		
		}
	});

	$('.toggle-trigger').on("click", function(e) {
		let $this = $(this);
		var href = $this.attr('href');
		$(href+' .toggle').trigger('click');


	});

});