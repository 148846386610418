var submitted = false;
$(document)
  // to prevent form from submitting upon successful validation
  .on("submit", function(ev) {
    if(ev.target.id == "form-evaluatie"){
      if(submitted == true){
        ev.preventDefault();
        console.log("Submit for form id "+ev.target.id+" intercepted");
      }else{
        submitted = true;
        console.log("Submitted");
        // Your text from a form field, converted to lower case
        var textFieldValueLower = document.getElementById('open_belangstelling_in_training').value.toLowerCase();

        // Array of words you want to check against the text, also converted to lower case
        var positiveWords = ['ja', 'wel', 'misschien'].map(word => word.toLowerCase());
        var negativeWords = ['nee', 'niet'].map(word => word.toLowerCase());

        // Check if the text contains any word from the array, case-insensitively
        var containsPositive = positiveWords.some(word => textFieldValueLower.includes(word));
        var containsNegative = negativeWords.some(word => textFieldValueLower.includes(word));

        // Use the result as needed
        if (containsPositive) {
          dataLayer.push({'interestedInMore': true});
        }else if(containsNegative){
          dataLayer.push({'interestedInMore': false});
        }else{
          dataLayer.push({'interestedInMore': 'unknown'});
        }
        dataLayer.push({'event': 'EvaluatieIngevuld'});
      }
    }
  });

$('form #training').on('change', function(){
    if($(this).val() != "0"){
        $('.hidebydefault').show();
    }else{
        $('.hidebydefault').hide();

    }
}); 