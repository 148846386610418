$(function () {
    $('.favorite-link')
        .on('click', function (e) {
            e.preventDefault();
			var clickedElement = $(this);
			var typeval = $(this).attr('data-type');
			var idval = $(this).attr('data-id');
			var notloggedin = $(this).hasClass('notloggedin');
			var notselected = $(this).hasClass('notselected');
			var selected = $(this).hasClass('selected');
			var collection = $(this).hasClass('collection');
			//var element $(this);
			if(notloggedin){
				///$('#loginModal').foundation('open');
				$('.reloadPage').val('true');
				console.log('reload');

			}else{
				
				if(notselected){
					$.post('/savefavorite', { type: typeval, id: idval })
					.done(function( data, element ) {
						//alert( "Data Loaded: add " + data.status );
						if(notloggedin){
							//$('#loginModal').foundation('open');
							$('.reloadPage').val('true');
							//alert('yaay');
						}else{
							$('.cue').show(400);
							setTimeout(
								function()
								{
									$('.cue').hide(400);
	
								}, 3000);
						} 
					});

					clickedElement.removeClass('notselected');
					clickedElement.addClass('selected');
					clickedElement.children('span').html("Bewaard")
					clickedElement.children('img').attr("src","data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkuNSAxMGMtMi40ODMgMC00LjUgMi4wMTUtNC41IDQuNXMyLjAxNyA0LjUgNC41IDQuNSA0LjUtMi4wMTUgNC41LTQuNS0yLjAxNy00LjUtNC41LTQuNXptMi41IDVoLTV2LTFoNXYxem0tNi41MjcgNC41OTNjLTEuMTA4IDEuMDg2LTIuMjc1IDIuMjE5LTMuNDczIDMuNDA3LTYuNDMtNi4zODEtMTItMTEuMTQ3LTEyLTE1LjgwOCAwLTYuNzY5IDguODUyLTguMzQ2IDEyLTIuOTQ0IDMuMTI1LTUuMzYyIDEyLTMuODQ4IDEyIDIuOTQ0IDAgLjc0Ni0uMTU2IDEuNDk2LS40MjMgMi4yNTMtMS4xMTYtLjkwMi0yLjUzNC0xLjQ0NS00LjA3Ny0xLjQ0NS0zLjU4NCAwLTYuNSAyLjkxNi02LjUgNi41IDAgMi4wNjMuOTcgMy45MDEgMi40NzMgNS4wOTN6Ii8+PC9zdmc+");
							
				}else{
					$.post('/deletefavorite', { type: typeval, id: idval })
					.done(function( data, element ) {
						//alert( "Data Loaded: delete " + data.status );
						if(notloggedin){
							$('#loginModal').foundation('open');
							//alert('yaay');
						}else{
							if(collection){
								$(".grid-item").show().filter(function () {
									return $(this).data('id') == idval;
								}).fadeOut(300, function(){ $(this).remove();});
							}else{
							}
						}
					});

					clickedElement.removeClass('selected');
					clickedElement.addClass('notselected');
					clickedElement.children('span').html("Bewaar voor later")
					clickedElement.children('img').attr("src","data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkuNSAxMGMtMi40ODMgMC00LjUgMi4wMTUtNC41IDQuNXMyLjAxNyA0LjUgNC41IDQuNSA0LjUtMi4wMTUgNC41LTQuNS0yLjAxNy00LjUtNC41LTQuNXptMi41IDVoLTJ2MmgtMXYtMmgtMnYtMWgydi0yaDF2MmgydjF6bS02LjUyNyA0LjU5M2MtMS4xMDggMS4wODYtMi4yNzUgMi4yMTktMy40NzMgMy40MDctNi40My02LjM4MS0xMi0xMS4xNDctMTItMTUuODA4IDAtNC4wMDUgMy4wOTgtNi4xOTIgNi4yODEtNi4xOTIgMi4xOTcgMCA0LjQzNCAxLjA0MiA1LjcxOSAzLjI0OCAxLjI3OS0yLjE5NSAzLjUyMS0zLjIzOCA1LjcyNi0zLjIzOCAzLjE3NyAwIDYuMjc0IDIuMTcxIDYuMjc0IDYuMTgyIDAgLjc0Ni0uMTU2IDEuNDk2LS40MjMgMi4yNTMtLjUyNy0uNDI3LTEuMTI0LS43NjgtMS43NjktMS4wMTQuMTIyLS40MjUuMTkyLS44MzkuMTkyLTEuMjM5IDAtMi44NzMtMi4yMTYtNC4xODItNC4yNzQtNC4xODItMy4yNTcgMC00Ljk3NiAzLjQ3NS01LjcyNiA1LjAyMS0uNzQ3LTEuNTQtMi40ODQtNS4wMy01LjcyLTUuMDMxLTIuMzE1LS4wMDEtNC4yOCAxLjUxNi00LjI4IDQuMTkyIDAgMy40NDIgNC43NDIgNy44NSAxMCAxM2wyLjEwOS0yLjA2NGMuMzc2LjU1Ny44MzkgMS4wNDggMS4zNjQgMS40NjV6Ii8+PC9zdmc+"); 
					
				
				}
			
			}
        });
});