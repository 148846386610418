$(function () {
	if($('.training-image-slider').length){
		   $('.training-image-slider').slick({
			  centerMode: true,
			  centerPadding: '0px',
			  slidesToShow: 3,
			  autoplay: false,
			  autoplaySpeed: 2000,
			  dots: true,
			  arrows: false,
	  
			  responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					arrows: false,
					centerMode: true,
					dots: true,
					slidesToShow: 3
				  }
				},
				{
				  breakpoint: 768,
				  settings: {
					arrows: false,
					centerMode: true,
					dots: true,
					slidesToShow: 2
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					arrows: false,
					centerMode: true,
					dots: true,
					slidesToShow: 1
				  }
				}
			  ]
			});
	}
});