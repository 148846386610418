/*$(function () {
    var $btn = $('#toon-alle-trainingsdata');
    var $items = $('#trainingsdata-items');

    if($btn.length == 1 ) {
        $items.addClass('closed');
        $btn.addClass('closed');

        $btn.on('click', function (e) {
            if( $btn.hasClass('closed') ) {
                $btn.removeClass('closed')
                $items.removeClass('closed');
                $btn.find('.text').text('Verberg trainingsdata');
            }
            else {
                $btn.addClass('closed');
                $items.addClass('closed');
                $btn.find('.text').text('Toon alle trainingsdata');
            }
        });
    }
});*/

$(function () {
	$('.training__cursusdata__load-more > button').on('click', function(){
		$('#trainingsdata-table tr[style*="display:none;"]:lt(10)').show();
		if($('#trainingsdata-table tr[style*="display:none;"]').length < 1){
			$('.training__cursusdata__load-more > button').hide();
		}
	});
});