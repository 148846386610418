$(function () {
    $('#inschrijven-evenement')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();
            $.post(form.attr("action"), form.serialize(), function (response) {
                $('#evenement-done').foundation('open');
            	dataLayer.push({'event': 'EventFormSubmit'});
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        })
    $('#inschrijven-webinar')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();
            $.post(form.attr("action"), form.serialize(), function (response) {
                $('#webinar-done').foundation('open');
            	dataLayer.push({'event': 'WebinarFormSubmit'});
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        })
    $('.events__planned .cf-button').on('click', function(){
    	var val = $(this).data('referrer');
    	$('.evenement-aanmelden__form input[name="referrer"]').val(val);
    });
    $('.events__missed .cf-button').on('click', function(){
    	var val = $(this).data('referrer');
    	$('.webinar-aanmelden__form select[name="webinar"] option[value="' + val + '"]').attr('selected', 'selected');
    });
});
