$(function () {
	var brochurelink = $('.cta-block__brochure a[data-open="signup-modal2"]').attr('href');

	if(brochurelink == undefined){
		brochurelink = $('.cluster-header__content__text__buttons__button:first-of-type a').attr('href');
	}
	if(brochurelink == undefined){
		$('.dowload-brochure-menu-item-brochure').hide();
	}



    $('a[href="#brochure"], a[href="#download"]').click(function(e){
    	e.preventDefault();
		var brochurelink = $('.cta-block__brochure a[data-open="signup-modal2"]').attr('href');
		if(brochurelink == undefined){
			brochurelink = $('.cluster-header__content__text__buttons__button:first-of-type a').attr('href');
		}
		//alert(brochurelink);
		window.location.href = brochurelink;
		//return false;
	});
});