
$(function () {
	/*Blogs start*/

	$('.Meer-body .slide-box').click(function(e) {
		e.preventDefault();
		var url = $(this).find('a.link').attr('href');
  		window.location = url
	});

});