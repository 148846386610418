$(function () {
    $('#brochure-aanvraag')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();

            $.post(form.attr("action"), form.serialize(), function (response) {
                $('#signup-done').foundation('open'); 
            	dataLayer.push({'event': 'BrochureFormSubmit'});
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        });
        
    $('#brochure-download')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();

            $.post(form.attr("action"), form.serialize(), function (response) {
                $('#signup-done').foundation('open'); 
            	dataLayer.push({'event': 'BrochureFormSubmit'});
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        });
        
    $('.download-brochure')
        .on("submit", function (e) {
        	if($('input[name="type"]').val() == "wp"){
            	dataLayer.push({'event': 'WhitepaperDownload'});
        	}else if($('input[name="type"]').val() == "wb"){ 
            	dataLayer.push({'event': 'BrochureDownload'});
        	}else{
            	dataLayer.push({'event': 'BrochureDownload'});
        	}
        });
        
    $('#gids-aanvraag')
        .on('formvalid.zf.abide', function (e, form) {
            e.preventDefault();
			$('.gids-form-error').hide();
            $.post(form.attr("action"), form.serialize(), function (response) {
            	if(response.status == "OK"){
					$('#gids-done').foundation('open'); 
                    window.dataLayer.push({'email': response.email});
                    window.dataLayer.push({'userId': response.userid});
                    window.dataLayer.push({'firstname': response.firstname});
                    window.dataLayer.push({'lastname': response.lastname});
                    window.dataLayer.push({'phone': response.telephone});
					dataLayer.push({'event': 'GidsFormSubmit'});
            	}else{
            		$('.gids-form-error').show();
            	}
            });
        })
        .on("submit", function (e) {
            e.preventDefault();
        });
});