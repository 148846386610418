$(function () {
    if($('.form-evaluatie #training').length){
    	var nowselected = $('.form-evaluatie #training').children("option:selected").text();
    	if(nowselected.indexOf("Maatwerk") >= 0){
			$('input[name="beoordeling_ontvangst"]').parent().parent().hide();
			$('input[name="beoordeling_lunch"]').parent().parent().hide();
			$('input[name="beoordeling_computerapparatuur"]').parent().parent().hide();
		}else{
			$('input[name="beoordeling_ontvangst"]').parent().parent().show();
			$('input[name="beoordeling_lunch"]').parent().parent().show();
			$('input[name="beoordeling_computerapparatuur"]').parent().parent().show();
		}
		
    	$('.form-evaluatie #training')
			.on("change", function () {
				var text = $(this).children("option:selected").text()
				if(text.indexOf("Maatwerk") >= 0){
					$('input[name="beoordeling_ontvangst"]').parent().parent().hide();
					$('input[name="beoordeling_lunch"]').parent().parent().hide();
					$('input[name="beoordeling_computerapparatuur"]').parent().parent().hide();
				}else{
					$('input[name="beoordeling_ontvangst"]').parent().parent().show();
					$('input[name="beoordeling_lunch"]').parent().parent().show();
					$('input[name="beoordeling_computerapparatuur"]').parent().parent().show();
				}
	        })
    
    }
});