$(function () {
	var $optionselector = '.search__filter__option';
	var $trainingselector = '.training';
	var $trainerselector = '.trainer';
	var $eventselector = '.event';
	var $whitepaperselector = '.whitepaper';
	var $articleselector = '.artikel';
	var $allesselector = '.trainingen-list__item';
	var $allesgridselector = '.training-list-item';
    var $option = $($optionselector);
    var $training = $($trainingselector);
    var $event = $($eventselector);
    var $whitepaper = $($whitepaperselector);
    var $trainer = $($trainerselector);
    var $article = $($articleselector);
    var $alles = $($allesselector);
    var $allesgrid = $($allesgridselector);

    if($option.length > 0 ) {
        //$header.addClass('closed');
        //$section.addClass('closed');

        $(document).on('click', '.search__filter__option', function (e) {
            if( $(this).hasClass('trainingen') ) {
                $($trainingselector).show();
                $($eventselector).hide();
                $($whitepaperselector).hide();
                $($articleselector).hide();
                $($trainerselector).hide();
                $option.removeClass('selected');
                $(this).addClass('selected');
            }
            else if( $(this).hasClass('artikelen') ) {
                $($trainingselector).hide();
                $($eventselector).hide();
                $($whitepaperselector).hide();
                $($articleselector).show();
                $($trainerselector).hide();
                $option.removeClass('selected');
                $(this).addClass('selected');
            }
            else if( $(this).hasClass('whitepapers') ) {
                $($trainingselector).hide();
                $($eventselector).hide();
                $($whitepaperselector).show();
                $($articleselector).hide();
                $($trainerselector).hide();
                $option.removeClass('selected');
                $(this).addClass('selected');
            }
            else if( $(this).hasClass('events') ) {
                $($trainingselector).hide();
                $($eventselector).show();
                $($whitepaperselector).hide();
                $($articleselector).hide();
                $($trainerselector).hide();
                $option.removeClass('selected');
                $(this).addClass('selected');
            }
            else if( $(this).hasClass('trainers') ) {
                $($trainingselector).hide();
                $($eventselector).hide();
                $($whitepaperselector).hide();
                $($articleselector).hide();
                $($trainerselector).show();
                $option.removeClass('selected');
                $(this).addClass('selected');
            }
            else{
            	$($allesselector).show();
            	$($allesgridselector).show();
                $option.removeClass('selected');
                $(this).addClass('selected');
            }
        });
    }
});